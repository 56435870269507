import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';

export default class Textarea extends Component {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.state = {
      value: props.value || '',
    };
  }

  onChange(e) {
    const { onChange } = this.props;
    const ev = Object.assign({}, e);
    const { target } = e;
    const { value } = target;
    this.setState({ value }, () => {
      onChange(ev);
    });
  }

  onBlur(e) {
    const { onBlur } = this.props;
    onBlur(e);
  }

  setRef(input) {
    this.input = input;
  }

  render() {
    const {
      theme,
      type,
      label,
      placeholder,
      error,
      classNames,
      leftIcon,
      rightIcon,
      disabled,
      noMargin,
      edit,
      ...others
    } = this.props;
    const { value } = this.state;
    const wrapperClassnames = classnames([
      'textarea',
      noMargin && 'textarea__no-margin',
    ]);
    const labelClassnames = classnames([
      'textarea__label',
      error && 'textarea__label--error',
    ]);
    const containerClassnames = classnames([
      'textarea__container',
      disabled && 'textarea__disabled',
      error && 'textarea__container--error',
    ]);
    const inputClassnames = classnames([
      `textarea__text textarea__text__${theme}`,
      leftIcon && `textarea__text__${theme}--icon-left`,
      rightIcon && `textarea__text__${theme}--icon-right`,
      (theme === 'default' && leftIcon && rightIcon) && 'textarea__text--no-border',
      disabled && 'textarea__text--disabled',
      classNames,
    ]);
    return (
      <div className={wrapperClassnames}>
        {label && <label className={labelClassnames}>{label}</label>}
        <div className={containerClassnames}>
          {
            edit ? (
              <textarea
                {...others}
                ref={this.setRef}
                type={type}
                placeholder={placeholder}
                className={inputClassnames}
                value={value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                autoComplete="new-password"
              >
                {value}
              </textarea>
            ) : <p className="textarea__preview">{value}</p>
          }
          {
            leftIcon
            && (
              <span className={`textarea__icon-left textarea__icon-left__${theme}`}>
                {
                  typeof leftIcon === 'string'
                    ? <i className={leftIcon} />
                    : leftIcon
                }
              </span>
            )
          }
          {
            rightIcon
            && (
              <span className={`textarea__icon-right textarea__icon-right__${theme}`}>
                {
                  typeof rightIcon === 'string'
                    ? <i className={rightIcon} />
                    : rightIcon
                }
              </span>
            )
          }
        </div>
        {error && <p className="textarea__err">{error}</p>}
      </div>
    );
  }
}
Textarea.propTypes = {
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  edit: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
Textarea.defaultProps = {
  disabled: false,
  noMargin: false,
  edit: true,
  theme: 'default',
  type: 'text',
  label: '',
  placeholder: '',
  error: '',
  classNames: '',
  leftIcon: '',
  rightIcon: '',
  value: '',
  onChange: noop,
  onBlur: noop,
};
