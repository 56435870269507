import { connect } from 'react-redux';
import ManageInformationLetter from '../../../views/Personnel/Letter/ManageInformationLetter.letter.view';
import { manageInformationLetter } from '../../../states/thunks/personnel.thunk';
import {getDataDeskripsi,getDataGolongan,getStatusKepegawaian,
  getDataJabatan,getDataSK } from '../../../states/thunks/sk.thunks';
import {
  getAktifNonAktifOptions,
} from '../../../states/thunks/options.thunk';

function mapStateToProps(state){
  return{
    user: state.user,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    handleManageInformationLetter: (payload, goBack, isEdit = false) => (
      dispatch(manageInformationLetter(payload, goBack, isEdit)) ),
    handelgetDataDeskripsi: payload => dispatch(getDataDeskripsi(payload)),
    handelgetDataGolongan: payload => dispatch(getDataGolongan(payload)),
    handelgetDataJabatan: payload => dispatch(getDataJabatan(payload)), 
    handelgetStatusKepegawaian: payload => dispatch(getStatusKepegawaian(payload)), 
    handleGetAktifNonAktifOptions: payload => dispatch(getAktifNonAktifOptions(payload)),
    handleGetDataSK : payload => dispatch(getDataSK(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageInformationLetter);
