import React, { PureComponent, Component, Fragment } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { commaFormatted } from "../../../../src/utils/transformer.util";
import moment from "moment";
import "moment/locale/id";

export default class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    const { datapegawai, kota } = this.props;
    this.state = {
      data_surat: {
        jenis_surat: "Surat Tugas",
        no_surat: "",
        judul_tujuan: "",
        isi_tujuan: "",
        tgl: "",
        data_penerima: {
          nama: datapegawai.nama_lengkap,
          ijazah: datapegawai.pendidikan_terakhir,
          tempat_tgl_lahir:
            datapegawai.tempat_lahir +
            " , " +
            moment(new Date(datapegawai.tanggal_lahir)).format("D MMMM YYYY"),
        //   no_reg: "Reg.Yad." + datapegawai.no_yadapen,
          unit_kerja: datapegawai.unit_kerja.name,
          alamat: datapegawai.alamat_tempat_tinggal,
          kota: datapegawai.kotamadya_kabupaten,
        },
        data: {
          // pangkat: "",
          jabatan: "",
          jabatan_name:"",
          // 
          honorarium:"",
          // kode: "",
          honorarium_tertulis: "",
          tgl_pembuatan_st: "",
          tgl_mulai: "",
          tgl_akhir: "",
          unitkerja:"",
          nama_ketua_yayasan:"",
          no_st:"",
          status_kepegawaian:"",
        },
        // data_baru: {
        //   pangkat: "",
        //   jabatan: "",
        //   gaji: "",
        //   gaji_tertulis: "",
        //   kode: "",
        //   tgl: "",
        //   masa: {
        //     tahun: "",
        //     bulan: "",
        //   },
        // },
        // keterangan: [],
        tembusan: [],
        tempat: datapegawai.unit_kerja.name,
        kota: kota,
        tgl_penetapan: "",
        ttd: "",
      },
    };
  }

  componentDidMount() {
    // console.log(this.props)
    this.getDataST();
  }

  async getDataST() {
    const terbilang = require("angka-menjadi-terbilang");
    const { handleGetDataST, id, datapegawai } = this.props;
    const temp = await handleGetDataST({
      nip: datapegawai.nip,
      id: id,
      unitkerja: datapegawai.unit_kerja.id_unit_kerja,
    //   status_kepegawaian: handleGetDataST.status_kepegawaian,
    });
    console.log(datapegawai);
    console.log(temp);
    // this.setState({data:temp.result})
    const datast = temp.result;
    const data = { ...this.state.data_surat };

    data.data.jabatan = datast.jabatan;
    data.data.jabatan_name = datast.jabatan_name;
          // 
          data.data.honorarium = "Rp." + commaFormatted(datast.honorarium) + ",00";
          // kode: "",
          data.data.honorarium_tertulis = "( " + terbilang(datast.honorarium) + " rupiah" + " )";
          data.data.tgl_pembuatan_st = moment(new Date(datast.tanggal_pembuatan_st)).format(
            "D MMMM YYYY");
            data.data.tgl_mulai = moment(new Date(datast.tanggal_mulai)).format(
            "D MMMM YYYY");
            data.data.tgl_akhir = moment(new Date(datast.tanggal_akhir)).format(
            "D MMMM YYYY");
          data.data.unitkerja = datast.prm_unit_kerja.name;
          data.data.nama_ketua_yayasan = datast.nama_ketua_yayasan;
          data.data.status_kepegawaian = datast.status_kepegawaian_arr.name;
          data.data.no_st = datast.no_st;
    //header
    
    //footer

    data.tembusan = datast.tembusan;
    
     console.log(data)
    this.setState({ data_surat: data });
    //console.log(datask_new.is_posting);
    if(datast.is_posting){
      this.props.handler()
    }
  }

  render() {
    return (
      <div style={{marginLeft:40}}>
        <div
          id="divPrint"
          style={{
            width: "auto",
            breakAfter: "avoid-page",
            border: "0px solid black",
          }}
        >
          <div className="preview_sk__content">
            <div className="preview_sk__header">
              <div className="preview_sk__header-title2">
                {/* <div className="preview_sk__header-title2--left">
                  <div style={{marginBottom:5}}>YAYASAN PANGUDI LUHUR</div>
                  <div style={{marginBottom:5}}>Jl.Dr.Sutomo 4,Telp (024) 8314004</div>
                  <div style={{marginBottom:5}}>SEMARANG 50244</div>
                </div> */}
                {/* <div className="preview_sk__header-title2--right">
                  <div style={{marginBottom:5}}>{this.state.data_surat.jenis_surat}</div>
                  <div style={{marginBottom:5}}>{this.state.data_surat.no_surat}</div>
                </div> */}
              </div>
              <div className="preview_sk__header-title">
                <div className="preview_sk__header-title--column">
                  <div className="preview_sk__header-title--column--bold"  style={{marginBottom:-10,fontFamily:"Times New Roman",marginTop:150}}>
                    <p style={{fontSize:20,textDecorationLine:'underline'}}>SURAT TUGAS</p>
                  </div>
                  <div  style={{marginBottom:20,fontFamily:"Times New Roman",fontSize:"16px"}}>
                    Nomor: {this.state.data_surat.data.no_st}
                    </div>
                  
                  {/* <div className="preview_sk__header-title--column--bold"  style={{marginBottom:5}}>
                    MEMUTUSKAN
                  </div> */}
                  {/* <div  style={{marginBottom:5}}>Menetapkan, bahwa Saudara yang namanya tersebut dalam surat
                  keputusan ini, terhitung mulai</div> */}
                  {/* <div style={{fontFamily:"Times New Roman",fontSize:"16px"}}>Yang bertanda tangan di bawah ini Pengurus Yayasan Pangudi Luhur, dengan ini memberikan tugas kepada :</div> */}
                  {/* <div className="preview_sk__header-title--column--bold"  style={{marginBottom:5}}>
                    tanggal {this.state.data_surat.tgl}
                  </div> */}
                </div>
              </div>
            </div>
            <div style={{fontFamily:"Times New Roman",fontSize:16,marginLeft:20}}>Yang bertanda tangan di bawah ini Pengurus Yayasan Pangudi Luhur, dengan ini memberikan tugas kepada :</div>
            <br />
            <table className="preview_sk__table" style={{marginTop:5}}>
              {/* <tr>
                <td valign="top">{this.state.data_surat.judul_tujuan}</td>
                <td valign="top" className="titik">
                  &nbsp;:&nbsp;
                </td>
                <td colSpan="4">{this.state.data_surat.isi_tujuan}</td>
              </tr> */}
              <tr>
                <td style={{fontFamily:"Times New Roman",fontSize:16}}>Nama</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2" style={{fontFamily:"Times New Roman",fontSize:16}}>{this.state.data_surat.data_penerima.nama}</td>
                <td className="judul">
                  {/* {this.state.data_surat.data_penerima.no_reg} */}
                </td>
              </tr>
              <tr>
                <td style={{fontFamily:"Times New Roman",fontSize:16}}>Ijazah</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="4" style={{fontFamily:"Times New Roman",fontSize:16}}>
                  {this.state.data_surat.data_penerima.ijazah}
                </td>
              </tr>
              <tr>
                <td className="judul" style={{fontFamily:"Times New Roman",fontSize:16}}>Tempat / Tanggal Lahir</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colspan="2" style={{fontFamily:"Times New Roman",fontSize:16}}>{this.state.data_surat.data_penerima.tempat_tgl_lahir}</td>
                {/* <td className="judul">
                  {this.state.data_surat.data_penerima.no_reg}
                </td> */}
              </tr>
              <tr>
                <td style={{fontFamily:"Times New Roman",fontSize:16}}>Alamat</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="4" style={{fontFamily:"Times New Roman",fontSize:16}}>
                  {this.state.data_surat.data_penerima.alamat} {this.state.data_surat.data_penerima.kota}
                </td>
              </tr>
              <tr>
                <td style={{fontFamily:"Times New Roman",fontSize:16}}>Status Kepegawaian</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="4" style={{fontFamily:"Times New Roman",fontSize:16}}>
                  {this.state.data_surat.data.status_kepegawaian}
                </td>
              </tr>
              <br></br>
              <tr>
                <td colspan="6" style={{fontFamily:"Times New Roman",fontSize:16}}>Sebagai {this.state.data_surat.data.jabatan_name} pada {this.state.data_surat.data.unitkerja}.</td>
              </tr> 
              {/* <tr>
                <td colspan="6">Demikianlah surat tugas ini, untuk diketahui dan dilaksanakan dengan sebaik-baiknya.</td>
              </tr> */}
              <tr>
                <td colspan="6" style={{fontFamily:"Times New Roman",fontSize:16,marginTop:5}}><br></br>
                <ol type='1' style={{marginLeft:15}}>
                  <li>Surat Tugas ini berlaku terhitung mulai {this.state.data_surat.data.tgl_mulai} sampai dengan {this.state.data_surat.data.tgl_akhir}</li>
                  <li style={{marginTop:10}}>Selama menjadi {this.state.data_surat.data.jabatan_name} diberikan honorarium sebesar {this.state.data_surat.data.honorarium} {this.state.data_surat.data.honorarium_tertulis}</li>
                </ol>
                </td>
              </tr> 
              <tr>
                <td colspan="6" style={{fontFamily:"Times New Roman",fontSize:16}}><br></br>Demikianlah surat tugas ini untuk diketahui dan dilaksanakan dengan sebaik-baiknya.</td>
              </tr>
              
              {/* <tr>
                <td>Unit Kerja</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td>
                  {this.state.data_surat.data_penerima.unit_kerja} di{" "}
                  {this.state.data_surat.kota}
                </td>
              </tr> */}
              {/* data lama */}
              {/* <tr className="batas"></tr>
              <tr>
                <td colSpan="6" className="batas">
                  LAMA
                </td>
              </tr>
              <tr>
                <td className="judul">Pangkat/gol.ruang gaji</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2">{this.state.data_surat.data_lama.pangkat}</td>
                <td className="judul" style={{borderLeft:'1px solid black'}}>&nbsp;Masa kerja golongan pada </td>
              </tr>
              <tr>
                <td>Jabatan</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2">{this.state.data_surat.data_lama.jabatan}</td>
                <td style={{borderLeft:'1px solid black' }}>&nbsp;tgl. {this.state.data_surat.data_lama.tgl}</td>
              </tr>
              <tr>
                <td>Gaji Pokok</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td>{this.state.data_surat.data_lama.gaji}</td>
                <td style={{ width: 105 }}>
                  {this.state.data_surat.data_lama.kode}
                </td>
                <td style={{borderLeft:'1px solid black' }}>
                  &nbsp;{this.state.data_surat.data_lama.masa.tahun} tahun{" "}
                  {this.state.data_surat.data_lama.masa.bulan} bulan
                </td>
              </tr>
              <tr>
                <td></td>
                <td colSpan="4">
                  &emsp;&nbsp;{this.state.data_surat.data_lama.gaji_tertulis}
                </td>
              </tr> */}

              {/* <tr className="batas"></tr>
              <tr>
                <td colSpan="6" className="batas">
                  BARU
                </td>
              </tr>
              <tr>
                <td className="judul">Pangkat/gol.ruang gaji</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2">{this.state.data_surat.data_baru.pangkat}</td>
                <td className="judul" style={{borderLeft:'1px solid black'}}>&nbsp; Masa kerja golongan pada </td>
              </tr>
              <tr>
                <td>Jabatan</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2">{this.state.data_surat.data_baru.jabatan}</td>
                <td style={{borderLeft:'1px solid black'}}>&nbsp; {this.state.data_surat.data_baru.tgl?'tgl.':''} {this.state.data_surat.data_baru.tgl}</td>
              </tr>
              <tr>
                <td>Gaji Pokok</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td>{this.state.data_surat.data_baru.gaji}</td>
                <td style={{ width: 105 }}>
                  {this.state.data_surat.data_baru.kode}
                </td>
                <td style={{borderLeft:'1px solid black'}}>
                  &nbsp; {this.state.data_surat.data_baru.masa.tahun?this.state.data_surat.data_baru.masa.tahun + " tahun ":""}
                  {this.state.data_surat.data_baru.masa.bulan?this.state.data_surat.data_baru.masa.bulan +" bulan":""}
                </td>
              </tr> */}
              {/* <tr>
                <td></td>
                <td colSpan="4">
                  &emsp;&nbsp;{this.state.data_surat.data_baru.gaji_tertulis}
                </td>
              </tr>
              <tr className="batas"></tr>
              <tr className="batas"></tr>
              <tr>
                <td valign="top">Keterangan Lain - lain</td>
                <td valign="top" className="titik">
                  &nbsp;:&nbsp;
                </td>
                <td colSpan="5" >
                  <ol>
                    {this.state.data_surat.keterangan.map((item, index) => {
                      console.log(item)
                      return (
                        <>
                          <li key={index}>{item}</li>
                          <div>{item}</div>
                        </>
                      );
                    })}
                  </ol>
                </td>
              </tr> */}
            </table>
            <div className="preview_sk__header">
              {/* <hr style={{marginTop:20, marginBottom:10}}></hr> */}
              <br />
              <div className="preview_sk__header-title2">
                <div className="preview_sk__header-title2--left">
                  {/* <div style={{marginBottom:3}}>Yth, Sdr. {this.state.data_surat.data_penerima.nama}</div>
                  <div style={{marginBottom:3}}>&emsp;&emsp;d.a. {this.state.data_surat.tempat}</div>
                  <div style={{marginBottom:3}}>&emsp;&emsp;{this.state.data_surat.kota}</div> */}
                  <br></br>
                  <div style={{marginBottom:3,marginTop:200,fontFamily:"Times New Roman",fontSize:16}}>Tembusan:</div>
                  {this.state.data_surat.tembusan.map((item, index) => {
                    return (
                      <>
                        <div style={{marginBottom:3,fontFamily:"Times New Roman",fontSize:16}}>{item.counter}. {item.note}</div>
                      </>
                    );
                  })}
                </div>
                <div className="preview_sk__header-title2--right" style={{fontFamily:"Times New Roman",fontSize:16}}>
                  {/* <div style={{marginBottom:3}}>Ditetapkan di Semarang</div> */}
                  <br></br>
                  <div style={{marginBottom:3,fontFamily:"Times New Roman",fontSize:16}}>Semarang, {this.state.data_surat.data.tgl_pembuatan_st}</div>
                  <div style={{marginBottom:3,fontFamily:"Times New Roman",fontSize:16}}>Pengurus Yayasan Pangudi Luhur</div>
                  <div style={{marginBottom:3,fontFamily:"Times New Roman",fontSize:16}}>Ketua,</div>
                  <br />
                  <br />
                  <br />
                  {this.state.data_surat.data.nama_ketua_yayasan}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ComponentToPrint.propTypes = {
  handelgetDataPegawai: PropTypes.func,
  handelgetDataST: PropTypes.func,
  // match: PropTypes.object.isRequired
};
ComponentToPrint.defaultProps = {
  handelgetDataPegawai: noop,
  handelgetDataST: noop,
};
