import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../../utils/transformer.util';
import { Button } from '../../../../../components/base';
import { printJournal } from '../../../../../utils/print.util';

function onClickPrint(header, content, footer) {
  printJournal(header, content, footer);
}

export default class NonKasJournalDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this._onGetDetails = this._onGetDetails.bind(this);
  }

  componentDidMount() {
    this._onGetDetails();
  }

  async _onGetDetails() {
    const { handleGetNonKasJournal, match } = this.props;
    const { id } = match.params;

    const payload = await handleGetNonKasJournal({ journal_number: id });
    this.setState({
      journal: payload,
    });
  }

  render() {
    const { journal } = this.state;
    return (
      <div>
        <Button
          title="Print"
          onClick={() => {
            onClickPrint('detail-bank-journal__header',
              'detail-bank-journal__table',
              'detail-bank-journal__footer');
          }}
        />
        <div id="detail-bank-journal" className="detail-bank-journal">
          {
            !isEmpty(journal) && (
              <div className="detail-bank-journal__content">
                <div className="detail-bank-journal__header">
                  <div className="detail-bank-journal__header-top">
                    <div className="detail-bank-journal__header-top--left">
                      <p>Yayasan Pangudi Luhur Pusat</p>
                    </div>
                    <div className="detail-bank-journal__header-title">
                      <h1>BUKTI JURNAL NON-KAS</h1>
                    </div>
                    <div className="detail-bank-journal__header-top--right">
                      <p>Nomor: {journal.journal_number}</p>
                      <p>Tanggal: {dateFormat(journal.date, 'DD-MM-YYYY')}</p>
                    </div>
                  </div>
                </div>
                <div className="detail-bank-journal__body">
                  <table className="detail-bank-journal__table">
                    <thead>
                      <tr>
                        <th rowSpan="2" width="2%">No.</th>
                        <th colSpan="2" width="30%">Kode</th>
                        <th rowSpan="2">Deskripsi</th>
                        <th rowSpan="2" width="5%">Unit</th>
                        <th rowSpan="2" width="15%">Debit</th>
                        <th rowSpan="2" width="15%">Kredit</th>
                      </tr>
                      <tr>
                        <th width="15%">Debit</th>
                        <th width="15%">Kredit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{ list.type === 'D' ? list.code_of_account : '' }</td>
                          <td>{ list.type === 'K' ? list.code_of_account : '' }</td>
                          <td>{list.description}</td>
                          <td>{list.unit_code}</td>
                          <td className="nominal">{commaFormatted(list.debit)}</td>
                          <td className="nominal">{commaFormatted(list.credit)}</td>
                        </tr>
                      ))
                    }
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="5" className="nominal">TOTAL</td>
                        <td className="nominal">{commaFormatted(journal.details.reduce((acc, item) => acc + item.debit, 0))}</td>
                        <td className="nominal">{commaFormatted(journal.details.reduce((acc, item) => acc + item.credit, 0))}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
NonKasJournalDetail.propTypes = {
  handleGetNonKasJournal: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
