import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { commaFormatted } from '../../../utils/transformer.util';
import { SearchSelect, Select } from '../../../components/base';
import * as DateUtil from '../../../utils/date.util';


export default class PayrollReport extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const { user } = props;
    const { user_group } = user;
    const isPusat = [8, 9, 10, 13, 14, 15, 17, 18].includes(user_group.id);
    const isPerwakilan = [2, 6, 11, 12].includes(user_group.id);

    this.role = 'unit';
    if (isPusat) {
      this.role = 'pusat';
    } else if (isPerwakilan) {
      this.role = 'perwakilan';
    }

    this.state = {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      report: [],
      reportType: 'unit',
      selectedUnit: '',
      selectedPerwakilan: {
        id: user.prm_perwakilan_id || null,
        label: user.perwakilan ? user.perwakilan.code : null,
      },
      selectedPos: '',
      tableHeaders: {},
      total: {},
    };

    this.reportTypes = [
      {
        value: '2', label: 'Konsolidasi',
      },
      {
        value: '1', label: 'Per Unit',
      },
    ];

    this.months = DateUtil.getMonths();
    this.years = DateUtil.getYears(2020, date.getFullYear() + 2);

    this._onSearch = this._onSearch.bind(this);
    this._getReport = this._getReport.bind(this);
    this._renderMonthOptions = this._renderMonthOptions.bind(this);
    this._renderYearOptions = this._renderYearOptions.bind(this);
    this._getUnitOptions = this._getUnitOptions.bind(this);
    this._handleFilterChange = this._handleFilterChange.bind(this);
    this._getTableHeaders = this._getTableHeaders.bind(this);
    this._renderReport = this._renderReport.bind(this);
  }

  componentDidMount() {
    const { handleGetCoa, handleGetPerwakilan } = this.props;
    handleGetCoa();
    handleGetPerwakilan();
    this._getUnitOptions();
    this._getTableHeaders();
  }

  _onSearch(val) {
    const {
      month = '',
      year = '',
      perwakilanId = null,
      unitId = null,
    } = val;

    this.setState(prevState => ({
      ...prevState,
      month,
      year,
      perwakilan_id: perwakilanId ? perwakilanId.id : null,
      unit_id: unitId ? unitId.id : null,
    }), () => {
      this._getReport();
    });
  }

  _handleFilterChange({ target }) {
    const { name, value } = target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }), () => {
      this._getReport();
    });
  }

  async _getTableHeaders() {
    const { handleGetTableHeaders } = this.props;
    const res = await handleGetTableHeaders();
    this.setState(prevState => ({
      ...prevState,
      tableHeaders: res,
    }), () => this._getReport());
  }

  async _getReport() {
    const {
      handleGetData, unit = {}, perwakilan = {}, user,
    } = this.props;
    const {
      month,
      year,
      selectedPerwakilan = {},
      reportType,
      selectedUnit = {},
      tableHeaders = {},
    } = this.state;
    const total = {
      gaji_bersih: 0,
    };
    const unitData = unit.list
      .find(item => parseInt(item.id, 0) === parseInt(selectedUnit.id, 0))
    || {};
    const perwakilanData = perwakilan.list
      .find(item => parseInt(item.id, 0) === parseInt(selectedPerwakilan.id, 0))
    || {};
    const res = await handleGetData({
      unit_id: reportType.value === '1' && unitData.attributes ? unitData.attributes.id : user.school_unit.id,
      perwakilan_id: reportType.value === '2' && perwakilanData.attributes ? perwakilanData.attributes.code : null,
      report_type: reportType.value ? reportType.value : 1,
      month: month.toString().padStart(2, '0'),
      year: year.toString(),
    });

    if (res && res[0]) {
      if (Object.keys(tableHeaders).length > 0) {
        Object.keys(tableHeaders).forEach((header) => {
          tableHeaders[header].forEach((item) => {
            item.detail_data.forEach((subItem) => {
              const { formula } = subItem;
              const values = res[0].detail_data.map(o => parseInt(o[formula], 0));
              total[formula] = values.reduce((a, b) => a + b);
            });
          });
        });
      }
      if (res[0] && res[0].detail_data) {
        total.gaji_bersih = res[0].detail_data.map(o => parseInt(o.gaji_bersih, 0))
          .reduce((a, b) => a + b);
        total.jml_penerimaan_bersih = res[0].detail_data
          .map(o => parseInt(o.jml_penerimaan_bersih, 0))
          .reduce((a, b) => a + b);
      }
      this.setState(prevState => ({
        ...prevState,
        report: res || [],
        total,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        report: [],
        total: {},
      }));
    }
  }

  async _getUnitOptions() {
    const { handleGetUnit } = this.props;
    const { selectedPerwakilan } = this.state;
    const filters = {
      perwakilan_id: selectedPerwakilan.id || null,
    };

    const res = await handleGetUnit(filters);
    if (res) {
      const unitList = res;
      this.setState({
        units: this.role === 'pusat' ? unitList
          : unitList.filter(o => o.attributes && o.attributes.unit_code !== 'P01'),
      });
    }
  }

  _renderMonthOptions() {
    const { month } = this.state;
    const options = Object.keys(this.months).map(item => ({
      value: item,
      label: this.months[item],
    }));

    return (
      <Select
        label="Bulan"
        data={options}
        name="month"
        value={month}
        onChange={this._handleFilterChange}
      />
    );
  }

  _renderYearOptions() {
    const { year } = this.state;
    const options = Object.keys(this.years).map(item => ({
      value: item,
      label: this.years[item],
    }));
    return (
      <Select
        label="Tahun"
        data={options}
        name="year"
        value={year}
        onChange={this._handleFilterChange}
      />
    );
  }

  _renderReportType() {
    const {
      reportType = {}, selectedPerwakilan, selectedUnit, units,
    } = this.state;
    const { perwakilan } = this.props;

    return (
      <>
        <div className="form-group-member">
          <SearchSelect
            async={false}
            name="reportType"
            label="Pilih Jenis Laporan"
            list={this.reportTypes}
            onChange={this._handleFilterChange}
            placeholder="Jenis Laporan"
            value={reportType}
            labelName="label"
            valueName="value"
          />
        </div>
        {
        reportType.value === '2' && this.role === 'pusat' && (
          <div className="form-group-member">
            <SearchSelect
              async={false}
              name="selectedPerwakilan"
              label="Pilih Perwakilan"
              list={perwakilan.list}
              onChange={this._handleFilterChange}
              placeholder="Perwakilan"
              value={selectedPerwakilan}
              labelName="title"
              valueName="id"
            />
          </div>
        )
        }
        {
          reportType.value === '1' && (
            <div className="form-group-member">
              <SearchSelect
                async={false}
                name="selectedUnit"
                label="Pilih Unit Kerja"
                list={units}
                onChange={this._handleFilterChange}
                placeholder="Unit Kerja"
                value={selectedUnit}
                labelName="title"
                valueName="id"
              />
            </div>
          )
        }
      </>
    );
  }

  _renderReport() {
    const { report, tableHeaders = {}, total = {} } = this.state;
    const { potongan = [], tunjangan = [] } = tableHeaders;
    return (
      <table className="table payroll-report-table">
        <thead>
          <tr>
            <th rowSpan={2}>No.</th>
            <th rowSpan={2}>Nomor Pegawai</th>
            <th rowSpan={2}>Nama</th>
            <th rowSpan={2}>Rekening Payroll</th>
            <th rowSpan={2}>Status Pegawai</th>
            <th rowSpan={2}>Kode Unit</th>
            { tunjangan.map(item => (
              <th colSpan={item.detail_data.length}>{item.name}</th>
            ))}
            { potongan.map(item => (
              <th colSpan={item.detail_data.length}>{item.name}</th>
            ))}
            <th rowSpan={2}>Gaji Bersih</th>
          </tr>
          <tr>
            { tunjangan.map(item => (
              <>
                { item.detail_data.map(subItem => (
                  <th>{subItem.name}</th>
                ))}
              </>
            ))}
            { potongan.map(item => (
              <>
                { item.detail_data.map(subItem => (
                  <th>{subItem.name}</th>
                ))}
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          { report.map(item => (
            <>
              {item.detail_data && item.detail_data.map((data, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{data.no_g}</td>
                  <td>{data.name}</td>
                  <td>{data.no_rek}</td>
                  <td>{data.status_kepegawaian}</td>
                  <td>{data.unit_kerja_id}</td>
                  {tunjangan.map(o => (
                    <>
                      {o.detail_data.map(detail => (
                        <td className="nominal">{commaFormatted(data[detail.formula])}</td>
                      ))}
                    </>
                  ))}
                  {potongan.map(o => (
                    <>
                      {o.detail_data.map(detail => (
                        <td className="nominal">{commaFormatted(data[detail.formula])}</td>
                      ))}
                    </>
                  ))}
                  <td className="nominal">{commaFormatted(data.jml_penerimaan_bersih)}</td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
        <tfoot>
          <tr className="total">
            <td colSpan="6">Jumlah</td>
            { tunjangan.map(item => (
              <>
                { item.detail_data.map(subItem => (
                  <td className="nominal">{commaFormatted(total[subItem.formula] || 0)}</td>
                ))}
              </>
            ))}
            { potongan.map(item => (
              <>
                { item.detail_data.map(subItem => (
                  <td className="nominal">{commaFormatted(total[subItem.formula] || 0)}</td>
                ))}
              </>
            ))}
            <td className="nominal">{commaFormatted(total.jml_penerimaan_bersih || 0)}</td>
          </tr>
        </tfoot>
      </table>
    );
  }

  render() {
    return (
      <div className="big-book-report">
        <div className="report-card">
          <div className="report-card__header">
            <div className="report-card__entries">
              <div className="form-group inline">
                { this._renderMonthOptions() }
                { this._renderYearOptions() }
                { (this.role === 'pusat' || this.role === 'perwakilan') && this._renderReportType() }
              </div>
            </div>
          </div>
          <div className="report-card__body">
            { this._renderReport() }
          </div>
        </div>
      </div>
    );
  }
}

PayrollReport.propTypes = {
  handleGetPerwakilan: PropTypes.func,
  handleGetCoa: PropTypes.func,
  handleGetUnit: PropTypes.func,
  perwakilan: PropTypes.object,
  user: PropTypes.object,
  unit: PropTypes.object,
  handleGetData: PropTypes.func,
  handleGetTableHeaders: PropTypes.func,
};

PayrollReport.defaultProps = {
  handleGetPerwakilan: noop,
  handleGetCoa: noop,
  handleGetUnit: noop,
  perwakilan: {},
  user: {},
  unit: {},
  handleGetData: noop,
  handleGetTableHeaders: noop,
};
