import axios from 'axios';
import { SERVER_URL, endpoints } from '../configs/api.config';
import {
  addDefaultPayloadInterceptor,
  getStatusValidatorInterceptor,
  mockInterceptor,
} from './interceptors.util';
import { getToken } from './token.util';

const baseConfig = {
  baseURL: SERVER_URL,
  timeout: 60000, // 60 seconds. for UAT TESTING
  // withCredentials: true,
  validateStatus: () => true,
  cancelToken: null,
  // crossdomain: true,
  // onDownloadProgress: () => {}, // Left this as a reference incase we need it
};

export async function GET(endpoint, path = '', options = {}, params = {}) {
  const token = await getToken();
  const config = {
    ...baseConfig,
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    ...options,
    method: 'get',
    endpoint,
    url: endpoints[endpoint] + path,
    params,
  };
  return axios(config).catch((error) => {
    if (error.status === 422 || error.status === 400) {
      throw error;
    } else if (error.message === 'Unauthenticated.') {
      throw error;
    }
  });
}
export async function POST(endpoint, data = {}, path = '', options = {}) {
  const token = await getToken();
  const config = {
    ...baseConfig,
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    ...options,
    method: 'post',
    endpoint,
    url: endpoints[endpoint] + path,
    data,
  };
  return axios(config).catch((error) => {
    if (error.status === 422 || error.status === 400) {
      throw error;
    } else if (error.message === 'Unauthenticated.') {
      throw error;
    } else {
      throw error;
    }
  });
}
export async function PUT(endpoint, data = {}, path = '', options = {}) {
  const token = await getToken();
  const config = {
    ...baseConfig,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...options,
    method: 'put',
    endpoint,
    url: endpoints[endpoint] + path,
    data,
  };
  return axios(config).catch((error) => {
    if (error.status === 422 || error.status === 400) {
      throw error;
    } else if (error.message === 'Unauthenticated.') {
      throw error;
    } else {
      throw error;
    }
  });
}
export async function DELETE(endpoint, data = {}, path = '', options = {}) {
  const token = await getToken();
  const config = {
    ...baseConfig,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...options,
    method: 'delete',
    endpoint,
    url: endpoints[endpoint] + path,
    data,
  };
  return axios(config);
}
export const initializeHTTPInterceptors = (store) => {
  axios.interceptors.request.use(mockInterceptor, Promise.reject);
  axios.interceptors.request.use(addDefaultPayloadInterceptor(store), Promise.reject);
  axios.interceptors.response.use(getStatusValidatorInterceptor(store), Promise.reject);
};
