import { connect } from 'react-redux';
import BudgetRecapsGlobal from '../../../../views/Finance/Budgeting/Budget/BudgetRecapsGlobal.finance.view';
import { getBudgetRecapsGlobal } from '../../../../states/thunks/finance.thunk';
import { getUnitOptions, getPerwakilanOptions, getBudgetPeriodeOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    perwakilan: state.options.perwakilan,
    unit: state.options.unit,
    periode: state.options.periode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBudgetRecaps: payload => dispatch(getBudgetRecapsGlobal(payload)),
    handleGetUnitOptions: payload => dispatch(getUnitOptions(payload)),
    handleGetBudgetPeriodeOptions: payload => dispatch(getBudgetPeriodeOptions(payload)),
    handleGetPerwakilanOptions: payload => dispatch(getPerwakilanOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetRecapsGlobal);
