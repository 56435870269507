import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { map, noop } from 'lodash';
import { Input, Button } from '../../../../../components/base';
import { commaFormatted } from '../../../../../utils/transformer.util';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import AdjustmentJournalCorrectionForm from './AdjustmentJournalCorrectionForm.component';

const MySwal = withReactContent(Swal);

export default class AdjustmentJournalForm extends Component {
  constructor(props) {
    super(props);
    this.renderCodeCorrection = this.renderCodeCorrection.bind(this);
    this._showModal = this._showModal.bind(this);
    this._onConfirmCorrection = this._onConfirmCorrection.bind(this);
    this._onChange = this._onChange.bind(this);
    this._prepCodeCorrection = this._prepCodeCorrection.bind(this);

    this.correctionFormRef = React.createRef();
  }

  _onConfirmCorrection() {
    const { getCorrection } = this.props;
    const form = this.correctionFormRef.current;
    const { state } = form;

    getCorrection(state);
    MySwal.close();
  }

  _prepCodeCorrection(selectedId) {
    const { form } = this.props;
    const { source = [] } = form.value;

    if (selectedId) {
      const transaction = source.find(o => o.id === parseInt(selectedId, 0));
      const data = {
        credit: transaction.credit,
        debit: transaction.debit,
        reference_id: selectedId,
      };
      this._showModal(data);
    }
  }

  _showModal(data = {}) {
    const { coa = [], type = '' } = this.props;
    const {
      code_of_account = '', description = '', debit = 0, credit = 0, timestamp,
      reference_id = null,
    } = data;
    const values = {
      code_of_account: code_of_account || {},
      description,
      debit,
      credit,
      timestamp,
      reference_id,
    };

    values.code_of_account = coa.list.find(o => o.code.toString() === code_of_account.toString());

    MySwal.fire({
      title: <p>Jurnal Koreksi</p>,
      html: (
        <AdjustmentJournalCorrectionForm
          coa={coa}
          values={values}
          onConfirm={this._onConfirmCorrection}
          onCancel={() => MySwal.close()}
          ref={this.correctionFormRef}
          type={type}
        />),
      showCancelButton: false,
      showConfirmButton: false,
    });

    return false;
  }

  _onChange(event) {
    const { value, checked } = event.target;
    const { onChange, form } = this.props;
    const { type } = form.value;

    if (type === 'CODE' && checked) {
      this._prepCodeCorrection(value);
    } else {
      onChange(event);
    }
  }

  renderCodeCorrection(type, data, corrections = []) {
    const { coa, onChange, form } = this.props;
    const { selected_ids } = form.value;
    const cIdx = selected_ids.findIndex(item => item.toString() === data.id.toString());
    let index = cIdx;
    let codeOfAccount = corrections[index] ? corrections[index].code_of_account : '';
    if (index === -1) {
      index = corrections.findIndex(item => item.id === data.id);
    }
    if (type !== 'CODE') {
      return false;
    }
    codeOfAccount = coa.list.find(o => o.code.toString() === codeOfAccount.toString());

    if (selected_ids.includes(data.id.toString())) {
      return (
        <>
          <td className="coa">
            <SelectCoa
              noMargin
              inputArray
              coa={coa}
              name="code_of_account"
              fieldName="corrections"
              arrayPosition={index}
              onClick={this._onChange}
              value={codeOfAccount}
              rightIcon="icon-search"
              data-source={data.id}
              edit={corrections[index] && !corrections[index].id}
            />
          </td>
          <td>
            <Input
              noMargin
              data-input-array
              data-array-position={index}
              data-field-name="corrections"
              name="description"
              onChange={onChange}
              value={corrections[index] ? corrections[index].description : ''}
              data-source={data.id}
              edit={corrections[index] && !corrections[index].id}
            />
          </td>
        </>
      );
    }
    return (
      <>
        <td /><td />
      </>
    );
  }

  render() {
    const {
      onChange, type, form, totals = [], onDeleteList,
    } = this.props;
    const {
      source = [], corrections = [],
      selected_ids = [], reversals = [],
    } = form.value;
    return (
      <>
        <table className="manage-adjustment-journal__form-table table">
          <thead>
            <tr>
              <th className="coa">Kode</th>
              <th>Keterangan</th>
              <th className="nominal">Debit</th>
              <th className="nominal">Credit</th>
              <th className="selection">Pilih</th>
              {
                (type === 'CODE') && (
                  <>
                    <th className="coa">Koreksi Kode</th>
                    <th>Keterangan Koreksi</th>
                  </>
                )
              }
            </tr>
          </thead>
          <tbody>
            {
              map(source, data => (
                <tr key={`source-${data.id}`}>
                  <td className="coa">
                    {data.code_of_account}
                  </td>
                  <td>
                    {data.description}
                  </td>
                  <td className="nominal">
                    {commaFormatted(data.debit)}
                  </td>
                  <td className="nominal">
                    {commaFormatted(data.credit)}
                  </td>
                  <td className="selection">
                    <input
                      type="checkbox"
                      onChange={this._onChange}
                      checked={data.is_corrected || selected_ids.includes(data.id.toString())}
                      value={data.id}
                      name="selected_ids"
                      disabled={data.is_corrected}
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        { reversals.length > 0 && (
        <>
          <h2>Jurnal Pembalik</h2>
          <table className="manage-adjustment-journal__form-table table">
            <thead>
              <tr>
                <th>Kode</th>
                <th>Deskripsi</th>
                <th>Debit</th>
                <th>Kredit</th>
              </tr>
            </thead>
            <tbody>
              { reversals.map(reversal => (
                <tr>
                  <td>{reversal.code_of_account}</td>
                  <td>{reversal.description}</td>
                  <td className="nominal">{commaFormatted(reversal.debit)}</td>
                  <td className="nominal">{commaFormatted(reversal.credit)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className="total">
                <td colSpan={2}>Total</td>
                <td className="nominal">{commaFormatted(totals.selected.debit)}</td>
                <td className="nominal">{commaFormatted(totals.selected.credit)}</td>
              </tr>
            </tfoot>
          </table>
        </>
        )}
        <h2>Jurnal Koreksi</h2>
        <table className="manage-adjustment-journal__form-table table">
          <thead>
            <tr>
              <th className="coa">Kode</th>
              <th>Keterangan</th>
              <th>Debit</th>
              <th>Kredit</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              map(corrections, (data, idx) => (
                <tr key={`adjustment_journal_data__${idx}`}>
                  <td className="coa">
                    {data.code_of_account}
                  </td>
                  <td>
                    {data.description}
                  </td>
                  <td className="nominal">
                    {commaFormatted(data.debit)}
                  </td>
                  <td className="nominal">
                    {commaFormatted(data.credit)}
                  </td>
                  <td width="95px">
                    { data.editable && (
                      <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
                        <div style={{ width: '35px%' }}>
                          <Button onClick={() => this._showModal(data)} icon="fontello-icon-edit" />
                        </div>
                        <div style={{ width: '35px' }}>
                          <Button onClick={() => onDeleteList(data.timestamp)} icon="fontello-icon-trash-empty" />
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            }
          </tbody>
          <tfoot>
            <tr className="total">
              <td colSpan={2}>Total</td>
              <td className="nominal">{commaFormatted(totals.correction.debit)}</td>
              <td className="nominal">{commaFormatted(totals.correction.credit)}</td>
              <td>&nbsp;</td>
            </tr>
            { type === 'AMOUNT' && (
              <tr className="manage-adjustment-journal__add-more">
                <td colSpan={7}>
                  <div>
                    <Button
                      icon="icon-plus"
                      onClick={this._showModal}
                    />
                  </div>
                </td>
              </tr>
            )}
          </tfoot>
        </table>
      </>
    );
  }
}
AdjustmentJournalForm.propTypes = {
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  getCorrection: PropTypes.func.isRequired,
  coa: PropTypes.object.isRequired,
  type: PropTypes.string,
  totals: PropTypes.object,
  form: PropTypes.shape({
    value: PropTypes.shape({
      corrections: PropTypes.array,
      reversals: PropTypes.array,
      selected_ids: PropTypes.array,
      source: PropTypes.array,
    }),
  }),
};
AdjustmentJournalForm.defaultProps = {
  onDeleteList: noop,
  onChange: noop,
  type: '',
  totals: {},
  form: {
    value: {
      corrections: [],
      reversals: [],
      selected_ids: [],
      source: [],
    },
  },
};
