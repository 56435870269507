import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import {
  Input, Button, Select,
} from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';

export default class BankJournalForm extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteJournalDetail = this._onDeleteJournalDetail.bind(this);
    this._filterCoa = this._filterCoa.bind(this);
  }

  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteJournalDetail(idx, id, fieldName) {
    const { onDeleteJournalDetail } = this.props;
    onDeleteJournalDetail(idx, id, fieldName);
  }

  _filterCoa() {
    const { coa, list } = this.props;
    if (list) {
      const activaCoa = list.some(el => el.code_of_account
        && el.code_of_account.toString().startsWith('11'));
      if (activaCoa) {
        return { list: coa.list.filter(el => el.code.toString().startsWith('11')) };
      }
      if (list[0].code_of_account !== '') {
        return { list: coa.list.filter(el => !el.code.toString().startsWith('11')) };
      }
      return coa;
    }
    return coa;
  }
  
  render() {
    const {
      list, error, onChange,
      fieldName, listField, title, tipe,
      coa, onSearchCoa, isPosted, isReconciliation, canAdd,
    } = this.props;
    return (
      <div>
        <h1>{title}</h1>
        <table className="manage-journal__form-table table">
          <thead>
            <tr>
              {
                map(listField.tableHead, (field, fieldIdx) => (
                  <th key={`manage_journal_form_table_head_${fieldIdx}`}>{field}</th>
                ))
              }
              {
                (!isPosted && !isReconciliation)
                && (<th />)
              }
            </tr>
          </thead>
          <tbody>
            {
            map(list, (data, idx) => (
              <tr key={`manage_journal_list__${idx}`}>
                {
                  map(listField.name, (field, nameIdx) => {
                    const { type = 'text', inputType = '' } = field;
                    if (nameIdx === 0) {
                      return (
                        <td key={`manage_journal_form_table_body_${idx}_${nameIdx}`}>{idx + 1}</td>
                      );
                    }
                    if (type === 'select') {
                      return (
                        <td key={`manage_journal_form_table_body_${idx}_${nameIdx}`}>
                          <Select
                            noMargin
                            data-input-array
                            data-array-position={idx}
                            data-field-name={fieldName}
                            name={field.name}
                            data={field.data}
                            onChange={onChange}
                            placeholder={field.placeholder}
                            value={data[field.name] || ''}
                            error={error[idx] ? error[idx][field.name] : ''}
                            disabled={isReconciliation}
                          />
                        </td>
                      );
                    }
                    if (type === 'selectCoa') {
                      return (
                        <td key={`manage_journal_form_table_body_${idx}_${nameIdx}`}>
                          <SelectCoa
                            noMargin
                            async={false}
                            inputArray
                            arrayPosition={idx}
                            fieldName={fieldName}
                            name={field.name}
                            placeholder={field.placeholder}
                            coa={coa}
                            onClick={onChange}
                            value={data[field.name]}
                            error={error[idx] ? error[idx][field.name] : ''}
                            rightIcon="icon-search"
                            onSearchCoa={onSearchCoa}
                            searchOnBlur
                            edit={!isReconciliation}

                          />
                        </td>
                      );
                    }
                    return (
                      <td
                        key={`manage_journal_form_table_body_${idx}_${nameIdx}`}
                        className={inputType === 'number' ? 'nominal' : ''}
                      >
                        <Input
                          noMargin
                          data-input-array
                          data-array-position={idx}
                          data-field-name={fieldName}
                          name={field.name}
                          type={type}
                          data-input-type={field.inputType}
                          placeholder=""
                          isNumber={field.inputType === 'number'}
                          onChange={onChange}
                          value={data[field.name] || ''}
                          error={error[idx] ? error[idx][field.name] : ''}
                          edit={!isReconciliation}
                        />
                      </td>
                    );
                  })
                }
                {
                    (!isPosted && !isReconciliation)
                    && (
                      <td>
                        <Button
                          onClick={() => this._onDeleteJournalDetail(idx, data.id, fieldName)}
                          icon="icon-trash-o"
                        />
                      </td>
                    )
                }
              </tr>
            ))
          }
            {
            ((tipe !== '2' && !isReconciliation && canAdd) || (tipe === '2' && list.length === 0))
              && (
              <tr className="manage-journal__add-more">
                <td
                  colSpan={!isPosted ? listField.tableHead.length + 1
                    : listField.tableHead.length}
                >
                  <div>
                    <Button
                      icon="icon-plus"
                      onClick={this._onAddList}
                    />
                  </div>
                </td>
              </tr>
              )
            }
          </tbody>
        </table>
      </div>
    );
  }
}
BankJournalForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onChange: PropTypes.func,
  title: PropTypes.string,
  fieldName: PropTypes.string,
  listField: PropTypes.object,
  coa: PropTypes.object,
  onSearchCoa: PropTypes.func,
  onDeleteJournalDetail: PropTypes.func,
  isReconciliation: PropTypes.bool,
  isPosted: PropTypes.bool,
  canAdd: PropTypes.bool,
  tipe: PropTypes.string.isRequired,
};
BankJournalForm.defaultProps = {
  onAddList: noop,
  onChange: noop,
  title: '',
  fieldName: '',
  listField: {},
  coa: { loading: true, list: [] },
  onSearchCoa: noop,
  onDeleteJournalDetail: noop,
  isPosted: false,
  canAdd: true,
  isReconciliation: false,
};
