export function printDetailBank() {
  const divToPrint = document.getElementById('detail-bank-journal');
  let htmlToPrint = `
  <style type="text/css">
  @media print {
    @page {size: A5 landscape;}

    .pagebreak {
      clear: both;
      page-break-after: always;
    }

    table th, table td {
        border:1px solid #000;
        padding;0.5em;
    }
    .detail-bank-journal__content {
      margin-top: 40px;
    }
  }
    .detail-bank-journal__header {
       padding: 20px;
       display: flex;
       flex-direction: column;
       justify-content: center;
       border: 1px solid black;
    }
    .detail-bank-journal__header-top {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       align-items: center;
    }
     .detail-bank-journal__header-top--left {
     font-size: 12px;
     font-weight: bold;
    }
     .detail-bank-journal__header-top--right {
     font-size: 12px;
     font-weight: bold;
    }
     .detail-bank-journal__header-title {
     margin-top: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
    }
     .detail-bank-journal__body {
     margin-top: 10px;
    }
     .detail-bank-journal__table {
     border-collapse: collapse;
     width: 100%;
     margin-bottom: 40px;
    }
     .detail-bank-journal__table td, .detail-bank-journal__table th {
     border: 1px solid black;
     padding: 8px;
    }
     .detail-bank-journal__table tr td:nth-child(1), .detail-bank-journal__table tr th:nth-child(1) {
     width: 1%;
    }
     .detail-bank-journal__table td {
     font-size: 12px;
    }
     .detail-bank-journal__table th {
     font-size: 12px;
     padding-top: 12px;
     padding-bottom: 12px;
     text-align: left;
    }
     .detail-bank-journal__footer {
     padding: 10px 0 0 0;
     border: 1px solid black;
    }
     .detail-bank-journal__footer-top {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
    }
     .detail-bank-journal__footer-top--left {
     padding: 0 10px;
     flex: 3;
     display: flex;
     flex-direction: column;
     font-size: 12px;
     font-weight: bold;
    }
     .detail-bank-journal__footer-top--right {
     flex: 2;
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
    }
     .detail-bank-journal__footer-top--right p {
     font-size: 12px;
     font-weight: bold;
    }
     .detail-bank-journal__footer-top--total {
     padding: 0 10px;
     display: flex;
     justify-content: space-between;
     border-bottom: 1px solid black;
    }
     .detail-bank-journal__footer-bottom {
     margin-top: 10px;
     display: flex;
     flex-direction: row;
     border-top: 1px solid black;
    }
     .detail-bank-journal__footer-bottom div {
     min-height: 10rem;
     padding: 10px;
     display: flex;
     flex-direction: column;
     flex: 1;
     justify-content: space-between;
     align-items: center;
     font-size: 14px;
    }
     .detail-bank-journal__footer-bottom div p:nth-child(2) {
     font-weight: bold;
    }
     .detail-bank-journal__footer-bottom div:not(:last-child) {
     border-right: 1px solid black;
    }
  </style>
  <div id="header_t">HEADER</div>
  <div id="footer_t">FOOTER</div>`;
  htmlToPrint += divToPrint.outerHTML;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}

export function printJournal(headerClass, contentClass, footerclass, options) {
  const header = (headerClass) ? document.getElementsByClassName(headerClass)[0].outerHTML : '';
  const content = document.getElementsByClassName(contentClass)[0].outerHTML;
  const footer = (footerclass) ? document.getElementsByClassName(footerclass)[0].outerHTML : '';

  let defaults = {
    pageSize: 'A5 landscape',
    printHeader: {
      repeated: true,
    },
  };
  if (options) {
    defaults = { ...options };
  }
  const htmlToPrint = `
  <link href="https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap" rel="stylesheet">
  <style type="text/css">
  @media print {
    @page {
      size: ${defaults.pageSize};
      margin-top: 50px;
    }

    body {
      margin: 5mm;
      font-family: 'Poppins', 'Muli', 'Arial';
      font-size: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 10px;
    }

    table td.nominal {
      text-align: right;
    }

    table tr {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }

    h1 {
      font-size: 12px;
      line-height: 100%;
      margin: 0;
      font-weight: bold;
    }

    #print-container{
      width: 100%;
    }

    #print-container > thead#print-table-header{
      display:${(defaults.printHeader.repeated) ? 'table-header-group' : 'table-row-group'};
      margin-bottom:5mm;
      width: 100%;
    }

    #print-container > tfoot{
      display:table-footer-group;
    }

    #print-container > thead#print-table-header > tr > th > div{
      border: 3px double #000000;
      padding: 8px;
    }

    #print-container > tbody div:first-child {
      margin-top:20px;
    }

    #print-container > tbody table {
      margin-top:20px;
    }

    #print-container > tbody#print-table-content > tr > td > table td,
    #print-container > tbody#print-table-content > tr > td > table th,
    #print-container > tfoot#print-table-content  > tr > td > div  {
      border:1px solid #000000;
      padding: 0 5px;
    }

    #print-container > tbody#print-table-content > tr > td > table > thead {
      display: table-row-group !important;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot td {
      font-weight: bold;
    }

    #print-container > tbody#print-table-content > tr > td > table > tfoot tr:first-child td {
      border-top: 5px double #000000;
    }

    div[class*="header-top"] {
      display: flex;
      flex-direction: row;
    }

    div[class*="header-title"] {
      display: flex;
      flex-direction: column;
      flex: 8 1;
    }

    div[class*="top--left"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="top--right"] {
      display: flex;
      flex-direction: column;
      flex: 2 1;
    }

    div[class*="footer-bottom"] {
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    div[class*="footer-bottom"] div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    div[class*="footer-bottom"] div p:first-child {
      margin-bottom: 20mm;
    }

    div[class*="header-title"] {
      clear: both;
    }

    #print-table-footer {
      display: table-footer-group;
    }

    #print-table-footer #page-counter{
      counter-increment: page;
      content: "Hal " counter(page) "/" counter(pages);
    }

    .page-break-avoid {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }
  }

  </style>

  <table id="print-container">
    <thead id="print-table-header">
      <tr>
        <th>
          ${header}
        </th>
      </tr>
    </thead>
    <tbody id="print-table-content">
      <tr>
        <td>${content}</td>
      </tr>
    </tbody>
    <tfoot id="print-table-footer">
      <tr>
        <td>
        ${footer}
        <td>
      </tr>
    </tfoot>
  </table>`;
  const popup = window.open('', 'popup');
  popup.document.body.innerHTML = htmlToPrint;
  popup.focus();
  popup.print();
}
