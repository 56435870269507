import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { SearchSelect, Button, Input } from '../../../../components/base';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';

const ManagePayrollJournal = ({
  handleManagePayrollJournal,
  handleGetCoa,
  handleGetPayrollJournal,
  match,
}) => {
  const [data, setData] = useState({});
  const [coa, setCoa] = useState([]);
  const [settings, setSettings] = useState({});

  const onSubmit = useCallback(() => {
    const newData = { ...data };
    const saveData = async () => {
      newData.is_posted = true;
      const res = await handleManagePayrollJournal(newData);
      if (res && res.message === 'OK') {
        setData(newData);
      }
    };
    saveData();
  }, [data]);

  const total = useMemo(() => {
    const { payroll = [] } = data;
    const detail = payroll.filter(o => o.type === 'detail');
    const rapel = payroll.filter(o => o.type === 'rapel'); 
    const totalRapel = rapel ? rapel.reduce((a, b) => a + b.amount, 0) : 0;
    let totalDebits = 0;
    let totalCredits = 0;
    if (detail) {
      const credits = detail.filter(a => a.code_of_account.toString().startsWith('5'));
      const debits = payroll.filter(a => a.code_of_account.toString().startsWith('1')
        || a.code_of_account.toString().startsWith('2'));
      totalDebits = debits.reduce((a, b) => a + b.amount, 0);
      totalCredits = credits.reduce((a, b) => a + b.amount, 0);
    }
    return {
      totalDebits,
      totalCredits,
      totalRapel,
    };
  }, [data]);

  const totalPotongan = useMemo(() => {
    const { deductions } = data;
    if (deductions) {
      const credits = deductions.filter(a => a.code_of_account.toString().startsWith('5'));
      const debits = deductions.filter(a => a.code_of_account.toString().startsWith('1')
        || a.code_of_account.toString().startsWith('2'));

      const totalDebits = debits.reduce((a, b) => a + b.amount, 0);

      const totalCredits = credits.reduce((a, b) => a + b.amount, 0);
      return {
        totalDebits,
        totalCredits,
      };
    }
    return {
      totalDebits: 0,
      totalCredits: 0,
    };
  }, [data]);

  const header = useMemo(() => {
    const { journal = {} } = data;
    const { unit = {}, prm_code_account = {} } = journal;

    if (data.is_posted) {
      return (
        <>
          <h1>
            Jurnal Penggajian {unit.name}
          </h1>
          <p>
            <b>Nomor Transaksi: {journal.journal_number}</b>
          </p>
          <p>
            <b>Tanggal: {journal.date}</b>
          </p>
          <p>
            <b>Kode Akun: {prm_code_account ? prm_code_account.title : ''}</b>
          </p>
          <br />
        </>
      );
    }
    return (
      <>
        <h2>
          Jurnal Penggajian {unit.name}
        </h2>
        <p>
          <b>Nomor Transaksi: {journal.journal_number}</b>
        </p>
        <p>
          <b>Tanggal: {journal.date}</b>
        </p>
        <br />
        <div className="list-form__wrapper">
          <div className="list-form__content--full">
            <SearchSelect
              async
              disabled={journal.is_posted}
              name="code_of_account"
              label="Kode Akun"
              placeholder="Pilih kode akun"
              list={coa}
              onSearch={handleGetCoa}
              onChange={(e) => {
                const newData = { ...data.journal };
                newData.code_of_account = e.target.value.id;
                setData({
                  ...data,
                  journal: newData,
                });
              }}
              value={coa.find(o => (journal.code_of_account ? o.id.toString() === journal.code_of_account.toString() : true))}
              rightIcon="icon-search"
            />
          </div>
        </div>
        <br />
      </>
    );
  }, [data, coa]);

  const details = useMemo(() => {
    const row = [];
    const { payroll = [], journal = {} } = data;
    const additional = payroll.find(o => parseInt(o.code_of_account, 10) === 21207 && o.editable) || {};
    if (payroll) {
      const detail = payroll.filter(o => o.type === 'detail');
      detail.map((item, index) => {
        if (item.editable) {
          return false;
        }
        const { code_of_account, prm_code_account, amount } = item;
        const key = `item_row_${index}`;
        const debit = (code_of_account.toString().startsWith(1)
          || code_of_account.toString().startsWith(2))
          ? commaFormatted(amount) : 0;
        const credit = code_of_account.toString().startsWith(5)
          ? commaFormatted(amount) : 0;

        row.push(
          <tr key={key}>
            <td>{code_of_account}</td>
            <td>{prm_code_account.title}</td>
            <td className="nominal">{debit}</td>
            <td className="nominal">{credit}</td>
          </tr>,
        );
        return row;
      });
      row.push(<tr key="new-line">
        <td>21207</td>
        <td>Titipan Penggajian Tunai</td>
        <td className="nominal">
          <Input
            type="text"
            name="additional"
            disabled={journal.is_posted}
            onChange={(e) => {
              const newData = JSON.parse(JSON.stringify(data.payroll)) || [];
              const index = newData.findIndex(o => parseInt(o.code_of_account, 10) === 21207 && o.editable);
              if (index > -1) {
                newData[index].amount = normalizeAmount(e.target.value);
              } else {
                newData.push({
                  code_of_account: '21207',
                  amount: normalizeAmount(e.target.value),
                  editable: true,
                  prm_code_account: {
                    code: 51109,
                    title: 'Titipan Penggajian Tunai',
                  }
                });
              }
              setData({
                ...data,
                payroll: newData,
              });
            }}
            value={commaFormatted(additional.amount)}
          />
        </td>
        <td className="nominal" />
      </tr>);
    }
    return row;
  }, [data, settings]);

  const rapel = useMemo(() => {
    const row = [];
    const { payroll = [] } = data;
    if (payroll) {
      const detail = payroll.filter(o => o.type === 'rapel');
      detail.map((item, index) => {
        if (item.editable) {
          return false;
        }
        const { code_of_account, prm_code_account, amount } = item;
        const key = `item_row_${index}`;
        const debit = (code_of_account.toString().startsWith(1)
          || code_of_account.toString().startsWith(2))
          ? commaFormatted(amount) : 0;
        const credit = code_of_account.toString().startsWith(5)
          ? commaFormatted(amount) : 0;

        row.push(
          <tr key={key}>
            <td>{code_of_account}</td>
            <td>{prm_code_account.title}</td>
            <td className="nominal">{debit}</td>
            <td className="nominal">{credit}</td>
          </tr>,
        );
        return row;
      });
    }
    return row;
  }, [data, settings]);

  const potongan = useMemo(() => {
    const row = [];
    const { deductions } = data;
    if (deductions) {
      deductions.map((item, index) => {
        const { code_of_account, prm_code_account, amount } = item;
        const key = `item_row_${index}`;
        const debit = (code_of_account.toString().startsWith(1)
          || code_of_account.toString().startsWith(2))
          ? commaFormatted(amount) : 0;
        const credit = code_of_account.toString().startsWith(5)
          ? commaFormatted(amount) : 0;

        row.push(
          <tr key={key}>
            <td>{code_of_account}</td>
            <td>{prm_code_account.title}</td>
            <td className="nominal">{debit}</td>
            <td className="nominal">{credit}</td>
          </tr>,
        );
        return row;
      });
    }
    return row;
  }, [data, settings]);

  useEffect(() => {
    const getData = async () => {
      const { id } = match.params;
      const res = await handleGetPayrollJournal({
        id,
      });
      setSettings(res.settings);
      setData(res.data);
    };

    const getCoaList = async () => {
      const res = await handleGetCoa();
      setCoa(res);
    };

    getCoaList();
    getData();
  }, []);

  return (
    <div id="payroll-journal-form">
      <div id="payroll-journal-form-header">
        {header}
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Kode Akun</th>
            <th>Keterangan</th>
            <th>BBM/BKM</th>
            <th>BBK/BKK</th>
          </tr>
        </thead>
        <tbody>
          { details }
        </tbody>
        <tfoot>
          <tr className="total">
            <td />
            <td />
            <td className="nominal">{commaFormatted(total.totalDebits) || 0}</td>
            <td className="nominal">{commaFormatted(total.totalCredits) || 0}</td>
          </tr>
        </tfoot>
      </table>

      <h2>Potongan Subsidi</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Kode Akun</th>
            <th>Keterangan</th>
            <th>BBM/BKM</th>
            <th>BBK/BKK</th>
          </tr>
        </thead>
        <tbody>
          { potongan }
        </tbody>
        <tfoot>
          <tr className="total">
            <td />
            <td />
            <td className="nominal">{commaFormatted(totalPotongan.totalDebits) || 0}</td>
            <td className="nominal">{commaFormatted(totalPotongan.totalCredits) || 0}</td>
          </tr>
        </tfoot>
      </table>
      <h2>RAPEL</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Kode Akun</th>
            <th>Keterangan</th>
            <th>BBM/BKM</th>
            <th>BBK/BKK</th>
          </tr>
        </thead>
        <tbody>
          { rapel }
        </tbody>
        <tfoot>
          <tr className="total">
            <td />
            <td />
            <td className="nominal">{commaFormatted(total.totalRapel) || 0}</td>
            <td className="nominal">0</td>
          </tr>
        </tfoot>
      </table>
      <div className="manage-cash-journal__footer">
        {
          data.journal && !data.journal.is_posted && (
            <Button
              type="submit"
              title="Simpan"
              onClick={() => onSubmit()}
            />
          )
        }
      </div>
    </div>
  );
};

export default ManagePayrollJournal;

ManagePayrollJournal.propTypes = {
  handleManagePayrollJournal: PropTypes.func.isRequired,
  handleGetPayrollJournal: PropTypes.func.isRequired,
  handleGetCoa: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
