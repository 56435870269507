import { connect } from 'react-redux';
import NonKasJournal from '../../../../views/Finance/Bookkeeping/NonKasJournal/NonKasJournal.bookkeeping.view';
import { get, post } from '../../../../states/thunks/nonCashJournal.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetNonKasJournal: payload => dispatch(get(payload)),
    handlePostingNonKasJournal: payload => dispatch(post(payload)),
  };
}

export default connect(null, mapDispatchToProps)(NonKasJournal);
