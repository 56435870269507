import { connect } from 'react-redux';
import PayrollJournal from '../../../../views/Finance/Bookkeeping/PayrollJournal/PayrollJournal.finance.view';
import { getPayrollJournal, postPayrollJournal } from '../../../../states/thunks/finance.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPayrollJournal: payload => dispatch(getPayrollJournal(payload)),
    handlePostPayrollJournal: payload => dispatch(postPayrollJournal(payload))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayrollJournal);
