import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  map, noop, isEqual, filter,
} from 'lodash';
import { Loading } from '../base';

export default class SelectCoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValue: '',
      filteredList: [],
    };
    this.clickHandlers = {};
    this._filterList = this._filterList.bind(this);
    this._setInputRef = this._setInputRef.bind(this);
    this._checkBlur = this._checkBlur.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onClick = this._onClick.bind(this);
    this._onClear = this._onClear.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this.selectCoaRefs = null;
    this.canBlur = true;
  }

  componentDidMount() {
    this._filterList();
  }

  componentDidUpdate() {
    this._filterList();
  }

  _filterList() {
    const { coa } = this.props;
    const { filteredList } = this.state;

    if (!isEqual(filteredList, coa.list)) {
      this.setState({
        filteredList: coa.list,
      });
    }
  }

  _setInputRef(ref) {
    this.selectCoaRefs = ref;
  }

  _onChange(event) {
    const val = event.target.value;
    const { onSearchCoa, coa } = this.props;
    let filteredList = [...coa.list];

    if (!onSearchCoa) {
      filteredList = filter(coa.list, (item) => {
        const { title } = item;
        if (title) {
          return title.toLowerCase()
            .search(val.toLowerCase()) > -1;
        }
        return true;
      });
    }

    this.setState({
      currentValue: val,
      filteredList,
    });
    onSearchCoa(val);
  }

  _checkBlur(type) {
    const {
      levelOne, levelTwo, levelThree,
      levelFour,
    } = this.props;
    switch (type) {
      case 'class': {
        if (!levelOne) {
          this.canBlur = false;
        }
        break;
      }
      case 'category': {
        if (!levelTwo) {
          this.canBlur = false;
        }
        break;
      }
      case 'group': {
        if (!levelThree) {
          this.canBlur = false;
        }
        break;
      }
      case 'account': {
        if (!levelFour) {
          this.canBlur = false;
        }
        break;
      }
      default: {
        this.canBlur = true;
        break;
      }
    }
  }

  _onClick(obj) {
    const {
      name, fieldName, onClick,
      inputArray, arrayPosition,
    } = this.props;
    const { 'data-source': dataSource } = this.props;
    this._checkBlur(obj.type);

    if (this.canBlur) {
      this.setState({
        currentValue: '',
      });
      onClick({
        target: {
          name,
          value: obj,
          dataset: {
            inputArray, arrayPosition, fieldName, dataSource,
          },
        },
      });
    }
  }

  _onClear() {
    const {
      name, fieldName, onClick,
      inputArray, arrayPosition
    } = this.props;
    const { 'data-source': dataSource } = this.props;
    this.setState({
      currentValue: '',
    });
    onClick({
      target: {
        name,
        value: { code: '', title: '' },
        dataset: { inputArray, arrayPosition, fieldName, dataSource },
      },
    });
  }

  _onBlur() {
    const { onSearchCoa, searchOnBlur = true } = this.props;
    if (this.canBlur) {
      this.setState({
        currentValue: '',
      });
      this.canBlur = false;
    } else {
      this.selectCoaRefs.focus();
    }
    this.canBlur = true;

    if(searchOnBlur) {
      onSearchCoa('');
    }
  }

  _getClickHandler(obj) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, obj.code)) {
      this.clickHandlers[obj.code] = () => this._onClick(obj);
    }
    return this.clickHandlers[obj.code];
  }

  render() {
    const {
      currentValue,
      filteredList,
    } = this.state;
    const {
      theme,
      coa,
      label,
      placeholder,
      error,
      classNames,
      disabled,
      value,
      leftIcon,
      rightIcon,
      noMargin,
      edit,
      levelOne,
      levelTwo,
      levelThree,
      levelFour,
      fieldName,
      arrayPosition,
      inputArray,
      onSearchCoa: onsearchcoa,
      searchOnBlur: searchonblur,
      ...others
    } = this.props;
    const loadingProps = { show: coa.loading };
    const wrapperClassnames = classnames([
      'select-coa',
      noMargin && 'select-coa__no-margin',
    ]);
    const labelClassnames = classnames([
      'select-coa__label',
      disabled && 'select-coa__text-disabled',
      error && 'select-coa__label--error',
    ]);
    const containerClassnames = classnames([
      'select-coa__container',
      disabled && 'select-coa__disabled',
      error && 'select-coa__container--error',
    ]);
    const titleClassnames = classnames([
      'select-coa__title',
      !levelOne && 'select-coa__text--disabled',
    ]);
    const categoryClassnames = classnames([
      'select-coa__category',
      !levelTwo && 'select-coa__text--disabled',
    ]);
    const groupClassnames = classnames([
      'select-coa__group',
      !levelThree && 'select-coa__text--disabled',
    ]);
    const accountClassnames = classnames([
      'select-coa__account',
      !levelFour && 'select-coa__text--disabled',
    ]);
    const inputClassnames = classnames([
      error && 'select-coa__text--error',
      value.code && 'select-coa__placeholder',
      disabled && 'select-coa__text-disabled select-coa__placeholder-disabled',

      `select-coa__text select-coa__text__${theme}`,
      leftIcon && `select-coa__text__${theme}--icon-left`,
      rightIcon && `select-coa__text__${theme}--icon-right`,
      disabled && 'select-coa__text--disabled',
      classNames,
    ]);

    return (
      <div className={wrapperClassnames}>
        {label && <label className={labelClassnames}>{label}</label>}
        <div className={containerClassnames}>
          { (value.code || currentValue) && edit
          && (
            <button
              type="button"
              className="select-coa__clear"
              onClick={this._onClear}
            >
              &times;
            </button>
          )
          }
          {
            edit ? (
              <input
                autoComplete="off"
                ref={this._setInputRef}
                type="text"
                placeholder={value.code ? `${value.code} - ${value.title}` : placeholder}
                className={inputClassnames}
                value={currentValue}
                onChange={this._onChange}
                onBlur={this._onBlur}
                data-field-name={fieldName}
                data-array-position={arrayPosition}
                data-input-array={inputArray}
                disabled={disabled}
                {...others}
              />
            ) : <p className="search-select__preview">{value.code}</p>
          }

          <div className="select-coa__list">
            {
              loadingProps.show
                ? <Loading loading={loadingProps} black />
                : map(filteredList, (data, idx) => {
                  const { category: categories = [] } = data;
                  return (
                    <div
                      key={`select_coa_list_${idx}`}
                      className="select-coa__item"
                    >
                      <div>
                        <button
                          type="button"
                          className={titleClassnames}
                          onMouseDown={this._getClickHandler({
                            code: data.code,
                            title: data.title,
                            isCredit: data.type === 'K',
                            type: 'class',
                          })}
                        >
                          <p>
                            {data.code} - {data.title}
                          </p>
                        </button>
                        {
                          map(categories, (category, categoryIdx) => {
                            const { group: groups = [] } = category;
                            return (
                              <div key={`coa_category_${idx}_${categoryIdx}`}>
                                <button
                                  type="button"
                                  className={categoryClassnames}
                                  onMouseDown={this._getClickHandler({
                                    code: category.code,
                                    title: category.title,
                                    type: 'category',
                                  })}
                                >
                                  <p>
                                    {category.code} - {category.title}
                                  </p>
                                </button>
                                {
                                  map(groups, (group, groupIdx) => {
                                    const { account: accounts = [] } = group;
                                    return (
                                      <div key={`coa_group_${idx}_${categoryIdx}_${groupIdx}`}>
                                        <button
                                          type="button"
                                          className={groupClassnames}
                                          onMouseDown={this._getClickHandler({
                                            code: group.code,
                                            title: group.title,
                                            type: 'group',
                                          })}
                                        >
                                          <p>
                                            {group.code} - {group.title}
                                          </p>
                                        </button>
                                        {
                                          map(accounts, (account, accountIdx) => (
                                            <button
                                              type="button"
                                              key={`coa_account_${idx}_${categoryIdx}_${groupIdx}_${accountIdx}`}
                                              className={accountClassnames}
                                              onMouseDown={this._getClickHandler({
                                                code: account.code,
                                                title: account.title,
                                                type: 'account',
                                              })}
                                            >
                                              <p>
                                                {account.code} - {account.title}
                                              </p>
                                            </button>
                                          ))
                                        }
                                      </div>
                                    );
                                  })
                                }
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  );
                })
            }
          </div>
          {
            (leftIcon && edit)
            && (
              <span className={`select-coa__icon-left select-coa__icon-left__${theme}`}>
                {
                  typeof leftIcon === 'string'
                    ? <i className={leftIcon} />
                    : leftIcon
                }
              </span>
            )
          }
          {
            (rightIcon && edit)
            && (
              <span className={`select-coa__icon-right select-coa__icon-right__${theme}`}>
                {
                  typeof rightIcon === 'string'
                    ? <i className={rightIcon} />
                    : rightIcon
                }
              </span>
            )
          }
        </div>
        {error && <p className="select-coa__err">{error}</p>}
      </div>
    );
  }
}
SelectCoa.propTypes = {
  levelOne: PropTypes.bool,
  levelTwo: PropTypes.bool,
  levelThree: PropTypes.bool,
  levelFour: PropTypes.bool,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  inputArray: PropTypes.bool,
  edit: PropTypes.bool,
  arrayPosition: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.object,
  name: PropTypes.string,
  fieldName: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  theme: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  onClick: PropTypes.func,
  coa: PropTypes.object.isRequired,
  onSearchCoa: PropTypes.func,
  searchOnBlur: PropTypes.bool,
};
SelectCoa.defaultProps = {
  levelOne: true,
  levelTwo: true,
  levelThree: true,
  levelFour: true,
  disabled: false,
  noMargin: false,
  inputArray: false,
  edit: true,
  arrayPosition: 0,
  theme: 'default',
  label: '',
  placeholder: '',
  error: '',
  classNames: '',
  name: '',
  fieldName: '',
  value: { code: '', title: '' },
  onClick: noop,
  leftIcon: '',
  rightIcon: '',
  onSearchCoa: noop,
  searchOnBlur: true,
};
