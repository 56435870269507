import { connect } from 'react-redux';
import ManagePayrollJournal from '../../../../views/Finance/Bookkeeping/PayrollJournal/ManagePayrollJournal.finance.view';
import { getPayrollJournal, managePayrollJournal } from '../../../../states/thunks/finance.thunk';
import { getReconciliationAccountOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getReconciliationAccountOptions(payload)),
    handleGetPayrollJournal: payload => dispatch(getPayrollJournal(payload)),
    handleManagePayrollJournal: (payload, goBack) => (
      dispatch(managePayrollJournal(payload, goBack))
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePayrollJournal);
