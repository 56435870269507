import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Checkbox } from '../../../../../components/base';

const FundRequestFormTableHeaders = (props) => {
  const {
    showEdit,
    checkedAll,
    onCheckAll,
    showEditRecommendation,
    showRecommendation,
  } = props;

  return (
    <thead>
      <tr>
        {
          showEditRecommendation && (
            <th width="2%">
              <Checkbox
                noMargin
                name="checkAll"
                onChange={onCheckAll}
                checked={checkedAll}
              />
            </th>
          )
        }
        <th width="5%">No.</th>
        <th width="7%">Kode</th>
        <th width="6%">Semester</th>
        <th>Keterangan</th>
        <th width="12%">Jumlah Anggaran</th>
        <th width="12%">Sisa Anggaran</th>
        <th width="12%">Dana Yang Bisa Diajukan</th>
        <th width="12%">Jumlah Permohonan Dana</th>
        {
          (showRecommendation)
          && (<th width="12%">Persetujuan Dana</th>)
        }
        { showEdit && (
          <th width="5%" />
        )}
      </tr>
    </thead>
  );
};

FundRequestFormTableHeaders.propTypes = {
  showEditRecommendation: PropTypes.bool,
  showRecommendation: PropTypes.bool,
  showEdit: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onCheckAll: PropTypes.func,
};
FundRequestFormTableHeaders.defaultProps = {
  showEditRecommendation: false,
  showRecommendation: PropTypes.bool,
  showEdit: false,
  checkedAll: false,
  onCheckAll: noop,
};

export default FundRequestFormTableHeaders;