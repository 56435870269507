import { connect } from 'react-redux';
import NonKasJournalDetail from '../../../../views/Finance/Bookkeeping/NonKasJournal/components/NonKasJournal.finance.component';
import { get } from '../../../../states/thunks/nonCashJournal.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetNonKasJournal: payload => dispatch(get(payload)),
  };
}

export default connect(null, mapDispatchToProps)(NonKasJournalDetail);
