import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../components/base';
import ContentTable from '../../../components/ContentTable/ContentTableST.component';
import { PERSONAL_ASSIGNMENT_LETTER_TABLE_FIELDS } from '../../../constants/personnel.constant';

export default class InformationLetter extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickDelete = this._onClickDelete.bind(this);
    this._onClickUnposting = this._onClickUnposting.bind(this);
    this._onClickView = this._onClickView.bind(this);

    const { location } = props;
    const { nip } = location.state.data;
    this.initialParam = { nip };
    this.state = {
      unit_kerja: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const { handleGetDataPegawai, location } = this.props;
    const { data } = location.state;
    const datapegawai = await handleGetDataPegawai({ nip: data.nip });
    this.setState({
      unit_kerja: datapegawai.result.unit_kerja.id_unit_kerja,
    });
  }

  _onClickBack = () => {
    const { history } = this.props;
    history.push('/dashboard/personalia/data-staff');
  };

  _onClickEdit(id) {
    const { history, location } = this.props;
    const { data } = location.state;
    const { unit_kerja } = this.state;
    history.push(`/dashboard/personalia/tambah-surat-tugas/${data.nip}`,
      { data: { id, nip: data.nip, unit_kerja }, isEdit: true });
  }

  _onClickDelete(id) {
    const { handleDeleteInformationLetter, location } = this.props;
    const { data } = location.state;
    return handleDeleteInformationLetter({ nip: data.nip, id });
  }

  _onClickUnposting(id) {
    const { handleUnpostingAssignmentLetter, location, history } = this.props;
    const { data } = location.state;
    return handleUnpostingAssignmentLetter({ nip: data.nip, id }, history.goBack);
  }

  _onClickView(id) {
    const { history, location } = this.props;
    const { datapegawai, kota } = location.state;
    history.push('/dashboard/personalia/preview-surat-tugas',
      { id, data: datapegawai, kota });
  }

  _onClickAdd() {
    const { history, location } = this.props;
    const { data } = location.state;
    const { unit_kerja } = this.state;
    history.push(`/dashboard/personalia/tambah-surat-tugas/${data.nip}`,
      { data: { nip: data.nip, unit_kerja }, isEdit: false });
  }

  render() {
    const { handleGetInformationLetter, user, history } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
    return (
      <div>
        {(permissions.includes('edit_st')) ? (
          <Button
            title="Tambah Surat Tugas"
            onClick={this._onClickAdd}
          />
        ) : null}
        <br />
        <Button
          title="Kembali"
          onClick={this._onClickBack}
        />
        <div className="information-letter__content">
          <ContentTable
            initialParams={this.initialParam}
            user={user}
            history={history}
            onSearchContent={handleGetInformationLetter}
            tableFields={PERSONAL_ASSIGNMENT_LETTER_TABLE_FIELDS}
            onClickEdit={this._onClickEdit}
            onClickView={this._onClickView}
            onClickDelete={this._onClickDelete}
            onClickUnposting={this._onClickUnposting}
          />
        </div>
      </div>
    );
  }
}
InformationLetter.propTypes = {
  handleGetInformationLetter: PropTypes.func,
  handleDeleteInformationLetter: PropTypes.func,
  handleUnpostingAssignmentLetter: PropTypes.func.isRequired,
  handleGetDataPegawai: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
InformationLetter.defaultProps = {
  handleGetInformationLetter: noop,
  handleDeleteInformationLetter: noop,
  handleGetDataPegawai: noop,
};
