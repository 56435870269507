import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { noop } from 'lodash';
import { commaFormatted } from '../../../../utils/transformer.util';
import { SearchSelect, Select } from '../../../../components/base';


export default class BudgetRecapsGlobal extends Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.getPerwakilanFilter = this.getPerwakilanFilter.bind(this);
    this.getBudgetPeriodeOptions = this.getBudgetPeriodeOptions.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.state = {
      list: [],
      filters: {
        data_type: 'PENGAJUAN',
        perwakilan: {},
        periode: {
          id: new Date().getFullYear(),
          title: new Date().getFullYear(),
        },
      },
    };
  }

  componentDidMount() {
    this.getBudgetPeriodeOptions();
  }

  onFilterChange(event) {
    const { target } = event;
    const { value, name } = target;
    let newFilters = {};
    if (name === 'perwakilan') {
      newFilters = {
        perwakilan: value,
        unit: {},
      };
    } else {
      newFilters = {
        [name]: value,
      };
    }
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...newFilters,
      },
    }), () => {
      this.getData();
      if (name === 'perwakilan') {
        this.getBudgetPeriodeOptions();
      }
      if (name === 'unit') {
        this.getBudgetPeriodeOptions();
      }
    });
  }

  async getData() {
    const { filters } = this.state;
    const { handleGetBudgetRecaps } = this.props;
    const res = await handleGetBudgetRecaps({
      perwakilan_id: filters.perwakilan.id,
      periode: filters.periode.id,
      dataType: filters.data_type,
    });

    this.setState(prevState => ({
      ...prevState,
      ...res,
    }));
  }


  async getPerwakilanFilter(payload = null) {
    const { handleGetPerwakilanOptions } = this.props;
    const res = handleGetPerwakilanOptions(payload);
    return res;
  }

  async getBudgetPeriodeOptions() {
    const { handleGetBudgetPeriodeOptions } = this.props;
    const { filters } = this.state;
    const { unit = {}, perwakilan = {} } = filters;
    const res = await handleGetBudgetPeriodeOptions({
      unit_id: unit.id,
      perwakilan_id: perwakilan.id,
    });

    this.setState({ periodeOptions: res });
  }

  renderFilter() {
    const { periode = {} } = this.props;
    const { filters } = this.state;
    return (
      <div className="filter inline">
        <SearchSelect
          async
          name="perwakilan"
          label="Perwakilan"
          placeholder="Pilih Perwakilan"
          onSearch={this.getPerwakilanFilter}
          onClick={this.onFilterChange}
          onChange={this.onFilterChange}
          value={filters.perwakilan}
          valueName="id"
          labelName="title"
        />
        <SearchSelect
          name="periode"
          label="Periode"
          placeholder="Pilih periode"
          onSearch={this.getUnitFilter}
          onClick={this.onFilterChange}
          onChange={this.onFilterChange}
          searchParams={{ periode: filters.periode.id }}
          value={filters.periode}
          list={periode.list}
          valueName="id"
          labelName="title"
        />
        <Select
          name="data_type"
          label="Tipe data"
          placeholder="Pilih tipe data"
          data={[
            { value: 'PENGAJUAN', label: 'Pengajuan' },
            { value: 'REKOMENDASI', label: 'Rekomendasi' },
            { value: 'PERSETUJUAN', label: 'Persetujuan' },
          ]}
          onChange={this.onFilterChange}
          value={filters.data_type}
        />
      </div>
    );
  }

  renderRows() {
    const { codes = [], grouped = {} } = this.state;
    const classes = ['PENDAPATAN', 'BEBAN', 'AKTIVA'];
    const rows = [];
    const total = {
      PENDAPATAN: {
        ypl: 0,
        bos: 0,
        intern: 0,
        committee: 0,
        total: 0,
      },
      BEBAN: {
        ypl: 0,
        bos: 0,
        intern: 0,
        committee: 0,
        total: 0,
      },
      AKTIVA: {
        ypl: 0,
        bos: 0,
        intern: 0,
        committee: 0,
        total: 0,
      },
    };

    classes.forEach((cls) => {
      const categories = codes[cls];
      rows.push(<tr key={`${cls}`}><td /><td colSpan={6}><b>{cls}</b></td></tr>);
      if (categories) {
        Object.keys(categories).forEach((cat) => {
          const group = categories[cat];
          rows.push(<tr key={`${cat}`}><td /><td colSpan={6}>&nbsp;&nbsp;<b>{cat}</b></td></tr>);
          if (group) {
            group.forEach((item) => {
              const mapped = grouped[item.code] || {};
              const {
                ypl = 0,
                bos = 0,
                intern = 0,
                committee = 0,
                subtotal = 0,
              } = mapped;
              const comp = (
                <tr key={`${cls}-${cat}-${item.code}`}>
                  <td>{item.code}</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{item.title}</td>
                  <td className="nominal">{ypl === 0 ? ' - ' : commaFormatted(ypl)}</td>
                  <td className="nominal">{bos === 0 ? ' - ' : commaFormatted(bos)}</td>
                  <td className="nominal">{intern === 0 ? ' - ' : commaFormatted(intern)}</td>
                  <td className="nominal">{committee === 0 ? ' - ' : commaFormatted(committee)}</td>
                  <td className="nominal">{commaFormatted(subtotal)}</td>
                </tr>
              );
              rows.push(comp);
              total[cls].ypl += parseFloat(ypl);
              total[cls].bos += parseFloat(bos);
              total[cls].intern += parseFloat(intern);
              total[cls].committee += parseFloat(committee);
              total[cls].total += parseFloat(subtotal);
            });
          }
        });
      }
    });

    const totalPendapatan = total.PENDAPATAN.ypl
      + total.PENDAPATAN.bos
      + total.PENDAPATAN.intern
      + total.PENDAPATAN.committee;
    const totalBeban = total.BEBAN.ypl
      + total.BEBAN.bos
      + total.BEBAN.intern
      + total.BEBAN.committee;
    const totalAktiva = total.AKTIVA.ypl
      + total.AKTIVA.bos
      + total.AKTIVA.intern
      + total.AKTIVA.committee;
   
    const labaRugi = {
      ypl: total.PENDAPATAN.ypl - total.BEBAN.ypl,
      bos: total.PENDAPATAN.bos - total.BEBAN.bos,
      intern: total.PENDAPATAN.intern - total.BEBAN.intern,
      committee: total.PENDAPATAN.committee - total.BEBAN.committee,
    };

    const saldo = {
      ypl: total.PENDAPATAN.ypl - total.BEBAN.ypl - total.AKTIVA.ypl,
      bos: total.PENDAPATAN.bos - total.BEBAN.bos - total.AKTIVA.bos,
      intern: total.PENDAPATAN.intern - total.BEBAN.intern - total.AKTIVA.intern,
      committee: total.PENDAPATAN.committee - total.BEBAN.committee - total.AKTIVA.committee,
    };

    return (
      <>
        { rows }
        <tr className="total">
          <td />
          <td>Total Pendapatan</td>
          <td className="nominal">{commaFormatted(total.PENDAPATAN.ypl)}</td>
          <td className="nominal">{commaFormatted(total.PENDAPATAN.bos)}</td>
          <td className="nominal">{commaFormatted(total.PENDAPATAN.intern)}</td>
          <td className="nominal">{commaFormatted(total.PENDAPATAN.committee)}</td>
          <td className="nominal">{commaFormatted(totalPendapatan)}</td>
        </tr>
        <tr className="total">
          <td />
          <td>Total Beban</td>
          <td className="nominal">{commaFormatted(total.BEBAN.ypl)}</td>
          <td className="nominal">{commaFormatted(total.BEBAN.bos)}</td>
          <td className="nominal">{commaFormatted(total.BEBAN.intern)}</td>
          <td className="nominal">{commaFormatted(total.BEBAN.committee)}</td>
          <td className="nominal">{commaFormatted(totalBeban)}</td>
        </tr>
        <tr className="total">
          <td />
          <td>Total Aktiva</td>
          <td className="nominal">{commaFormatted(total.AKTIVA.ypl)}</td>
          <td className="nominal">{commaFormatted(total.AKTIVA.bos)}</td>
          <td className="nominal">{commaFormatted(total.AKTIVA.intern)}</td>
          <td className="nominal">{commaFormatted(total.AKTIVA.committee)}</td>
          <td className="nominal">{commaFormatted(totalAktiva)}</td>
        </tr>
        <tr className="total">
          <td />
          <td>Ikhtisar Laba-Rugi</td>
          <td className="nominal">{commaFormatted(labaRugi.ypl)}</td>
          <td className="nominal">{commaFormatted(labaRugi.bos)}</td>
          <td className="nominal">{commaFormatted(labaRugi.intern)}</td>
          <td className="nominal">{commaFormatted(labaRugi.committee)}</td>
          <td className="nominal">{commaFormatted(labaRugi.ypl + labaRugi.bos + labaRugi.intern + labaRugi.committee)}</td>
        </tr>
        <tr className="total">
          <td />
          <td>Saldo</td>
          <td className="nominal">{commaFormatted(saldo.ypl)}</td>
          <td className="nominal">{commaFormatted(saldo.bos)}</td>
          <td className="nominal">{commaFormatted(saldo.intern)}</td>
          <td className="nominal">{commaFormatted(saldo.committee)}</td>
          <td className="nominal">{commaFormatted(saldo.ypl + saldo.bos + saldo.intern + saldo.committee)}</td>
        </tr>
      </>
    );
  }

  render() {
    const { filters = {} } = this.state;
    const { periode = {} } = filters;
    return (
      <div className="content_wrapper">
        <div className="table_title" id="printable-header">
          <h1>REKAP RAPBU, PERSETUJUAN DAN REALISASI</h1>
          <h1>TAHUN { periode.id }</h1>
          <h2>YAYASAN PANGUDI LUHUR</h2>
        </div>
        <div className="print">
          <ReactHTMLTableToExcel
            id="export-to-xls-button"
            className="button "
            table="printable"
            filename={`Rekap RAPBU Yayasan Pangudi Luhur Tahun ${periode.id}`}
            sheet={periode.id}
            buttonText="Unduh ke Excel"
          />
        </div>
        {this.renderFilter()}
        <table className="table" id="printable">
          <thead>
            <tr>
              <th>GROUP</th>
              <th>KETERANGAN</th>
              <th>YPL</th>
              <th>BOS</th>
              <th>INTERN</th>
              <th>KOMITE</th>
              <th>JUMLAH</th>
            </tr>
          </thead>
          <tbody>
            {this.renderRows()}
          </tbody>
        </table>

      </div>
    );
  }
}

BudgetRecapsGlobal.propTypes = {
  handleGetBudgetRecaps: PropTypes.func,
  handleGetPerwakilanOptions: PropTypes.func,
  handleGetBudgetPeriodeOptions: PropTypes.func,
  periode: PropTypes.object,
};

BudgetRecapsGlobal.defaultProps = {
  handleGetBudgetRecaps: noop,
  handleGetPerwakilanOptions: noop,
  handleGetBudgetPeriodeOptions: noop,
  periode: {
    list: [
      {
        id: new Date().getFullYear(),
        title: new Date().getFullYear(),
      },
    ],
  },
};
