import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, filter,
  dropRight,
} from 'lodash';
import { Button } from '../../../../components/base';
import ListForm from '../../../../components/ListForm/ListForm.component';
import BudgetForm from './components/BudgetForm.component';
import language from '../../../../languages';
import { validateManageBudget } from '../../../../utils/validation.util';
import { errorAlert } from '../../../../utils/alert.util';
import { datetimeFormat, normalizeAmount } from '../../../../utils/transformer.util';

export default class ManageBudget extends Component {
  constructor(props) {
    super(props);
    this.onSetForm = this.onSetForm.bind(this);
    this.onUpdateForm = this.onUpdateForm.bind(this);
    this.onUpdateError = this.onUpdateError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onAddSemester = this.onAddSemester.bind(this);
    this.onDeleteSemester = this.onDeleteSemester.bind(this);
    this.onClearForm = this.onClearForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSearchCoa = this.onSearchCoa.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);

    this.renderWorkflowHistory = this.renderWorkflowHistory.bind(this);

    const defaultRapbu = {
      estimasi_surplus_defisit: null,
      estimasi_ypl: null,
      estimasi_komite: null,
      estimasi_intern: null,
      estimasi_bos: null,
      saldo: null,
      saldo_ypl: null,
      saldo_komite: null,
      saldo_intern: null,
      saldo_bos: null,
      status_surplus_defisit: '',
      total_pendapatan: null,
      total_pendapatan_ypl: null,
      total_pendapatan_komite: null,
      total_pendapatan_intern: null,
      total_pendapatan_bos: null,
      total_pengeluaran: null,
      total_pengeluaran_ypl: null,
      total_pengeluaran_komite: null,
      total_pengeluaran_intern: null,
      total_pengeluaran_bos: null,
      total_inventaris: null,
      total_inventaris_ypl: null,
      total_inventaris_komite: null,
      total_inventaris_intern: null,
      total_inventaris_bos: null,
      total_beban: null,
    };

    this.state = {
      status: 'VIEW',
      form: {
        value: {
          period: '2019',
          semesterOne: [],
          semesterTwo: [],
        },
        error: {
          period: '',
          semesterOne: [],
          semesterTwo: [],
        },
      },
      pendapatan: [],
      pengeluaran: [],
      inventaris: [],
      rapbuSummary: {
        semesterOne: { ...defaultRapbu },
        semesterTwo: { ...defaultRapbu },
      },
      recommendations: {},
      workflow: [],
    };
  }

  componentDidMount() {
    this.onSearchCoa();
    this.onSetForm();
  }

  onSearchCoa(keyword = null) {
    const { handleGetCoaOptions, location } = this.props;
    const { type } = location.state;
    if (type === 'OUTCOME') {
      handleGetCoaOptions({
        keyword,
        classes: [50000, 10000],
        categories: [51000, 52000, 53000, 54000, 55000, 56000, 13000],
      });
    } else {
      handleGetCoaOptions({
        keyword,
        classes: [40000],
      });
    }
  }

  async onSetForm() {
    const { handleGetBudgetDetail, location } = this.props;
    const {
      account_unique_id, head_unique_id, type, data,
    } = location.state;
    const { periode } = data;
    const res = await handleGetBudgetDetail({ account_unique_id, head_unique_id, type });
    this.setState({
      status: (!isEmpty(res.ganjil) || !isEmpty(res.genap)) ? 'VIEW' : 'ADD',
      form: {
        value: {
          period: periode,
          semesterOne: res.ganjil || [],
          semesterTwo: res.genap || [],
        },
        error: {
          period: '',
          semesterOne: [],
          semesterTwo: [],
        },
      },
      pendapatan: res.pendapatan || [],
      pengeluaran: res.pengeluaran || [],
      inventaris: res.inventaris || [],
      rapbuSummary: {
        semesterOne: res.ganjil || [],
        semesterTwo: res.genap || [],
      },
      recommendations: res.recommendations || {},
      workflow: res.workflow || [],
    });
  }

  onUpdateForm(data, idx) {
    const formName = Number(data.semester) === 1 ? 'semesterOne' : 'semesterTwo';
    this.setState((prevState) => {
      const newValue = prevState.form.value;
      const newError = prevState.form.error;
      newValue[formName][idx] = data;
      newError[formName][idx] = {};
      return {
        form: {
          value: {
            ...newValue,
          },
          error: {
            ...prevState.form.error,
            ...newError,
          },
        },
      };
    });
  }

  onUpdateError(data, error, idx) {
    const formName = Number(data.semester) === 1 ? 'semesterOne' : 'semesterTwo';
    this.setState((prevState) => {
      const newValue = prevState.form.error;
      newValue[formName][idx] = error;
      return {
        form: {
          value: {
            ...prevState.form.value,
          },
          error: {
            ...newValue,
          },
        },
      };
    });
  }

  onRemoveFile(idx, semester) {
    this.setState((prevState) => {
      const { form } = prevState;
      const prevData = form.value[semester];
      const newData = prevData.slice(0, prevData.length);

      newData[idx].file = null;

      return {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            ...{ [semester]: newData },
          },
        },
      };
    });
  }

  onFormChange(event, formName) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;

    this.setState((prevState) => {
      const { form: prevForm } = prevState;
      let newSemester = [];
      let newSemesterError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        newSemester = JSON.parse(JSON.stringify(prevForm.value[formName]));
        newSemesterError = JSON.parse(JSON.stringify(prevForm.error[formName]));
        newSemester[arrayPosition][name] = formattedValue;
        if (name === 'parameter_code') {
          newSemester[arrayPosition].title = formattedValue.title;
        }
        if (!isEmpty(newSemesterError[arrayPosition])) {
          newSemesterError[arrayPosition][name] = '';
        }
      }
      const error = {
        ...prevState.form.error,
        ...(inputArray
          ? { [formName]: newSemesterError }
          : { [name]: '' }),
      };
      error.semesterOne = filter(error.semesterOne, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });
      error.semesterTwo = filter(error.semesterTwo, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });
      return {
        form: {
          value: {
            ...prevForm.value,
            ...(inputArray
              ? { [formName]: newSemester }
              : { [name]: formattedValue }),
          },
          error,
        },
      };
    });
  }

  onAddSemester(fieldName) {
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {},
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  onDeleteSemester(fieldName, idx) {
    this.setState((prevState) => {
      const list = prevState.form.value[fieldName];
      const listError = prevState.form.error[fieldName];
      list.splice(idx, 1);
      return {
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [...list],
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [...listError],
          },
        },
      };
    });
  }

  onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    const { form, status } = this.state;
    const { handleAddBudgetDetail, location } = this.props;
    const error = validateManageBudget(form.value);
    const semesterOneErr = filter(error.semesterOne, value => Object.keys(value).length !== 0);
    const semesterTwoErr = filter(error.semesterTwo, value => Object.keys(value).length !== 0);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(semesterOneErr)) {
      error.semesterOne = filter(error.semesterOne, value => Object.keys(value).length !== 0);
    }
    if (isEmpty(semesterTwoErr)) {
      error.semesterTwo = filter(error.semesterTwo, value => Object.keys(value).length !== 0);
    }
    if (!isEmpty(semesterOneErr) || !isEmpty(semesterTwoErr)) {
      errorAlert({
        title: 'Data Ada Yang Salah',
      });
    } else {
      try {
        const res = await handleAddBudgetDetail({
          ...location.state,
          ...form.value,
        }, status);

        this.setState((prevState) => {
          const newSemesterOne = filter(res, data => data.semester === 1);
          const newSemesterTwo = filter(res, data => data.semester === 2);
          const oldSemesterOne = dropRight(prevState.form.value.semesterOne, newSemesterOne.length);
          const oldSemesterTwo = dropRight(prevState.form.value.semesterTwo, newSemesterTwo.length);

          return ({
            status: 'VIEW',
            form: {
              value: {
                ...prevState.form.value,
                semesterOne: [...oldSemesterOne, ...newSemesterOne],
                semesterTwo: [...oldSemesterTwo, ...newSemesterTwo],
              },
              error: {
                ...prevState.form.error,
              },
            },
          });
        });
      } catch (err) {
        // err
      }
    }
  }

  renderWorkflowHistory(workflow) {
    const rows = [];
    if (workflow) {
      workflow.forEach((item, idx) => {
        const {
          prev_role, next_role, action, updated_at, remarks, is_done,
        } = item;
        let flowAction = (action === 'reject')
          ? 'mengembalikan ke' : ' mengajukan ke';
        const prevRole = (prev_role === 'Korektor Perwakilan') ? 'Perwakilan' : prev_role;
        const nextRole = (next_role === 'Korektor Perwakilan') ? 'Perwakilan' : next_role;

        if (is_done) {
          flowAction = 'RAPBU telah disetujui oleh Pengurus';
        } else {
          flowAction = `${prevRole} ${flowAction} ${nextRole}`;
        }

        rows.push([
          <tr key={`workflow_history_item_${idx}`}>
            <td>{idx + 1}</td>
            <td>{flowAction}</td>
            <td>{remarks}</td>
            <td>{datetimeFormat(updated_at)}</td>
          </tr>,
        ]);
      });
      return (
        <table className="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Flow</th>
              <th>Catatan</th>
              <th>Tanggal</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      );
    }

    return false;
  }

  renderButton() {
    return (
      <div>
        <Button
          type="submit"
          title="Save"
        />
      </div>
    );
  }

  render() {
    const {
      form, status, recommendations, workflow,
    } = this.state;
    const {
      semesterOne, semesterTwo,
    } = form.value;
    const { semesterOne: semesterOneError, semesterTwo: semesterTwoError } = form.error;
    const {
      coa, location, handleEditBudgetDetail,
      handleDeleteBudgetDetail, user, handleFileUpload, handleFileDownload,
    } = this.props;
    const { type, code } = location.state;
    return (
      <div className="manage-budget">
        <h1>
          {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET__TITLE}&nbsp;
          {language.translate[`FINANCE__BUDGETING__MANAGE_BUDGET__${type}`]}
        </h1>
        <form onSubmit={this.onSubmit}>
          <ListForm>
            <h1>Periode {form.value.period}</h1>
            <BudgetForm
              code={code}
              status={status}
              coa={coa}
              type={type}
              semesterOne={semesterOne}
              semesterTwo={semesterTwo}
              semesterOneError={semesterOneError}
              semesterTwoError={semesterTwoError}
              onAddSemester={this.onAddSemester}
              onDeleteSemester={this.onDeleteSemester}
              onChange={this.onFormChange}
              onRemoveFile={this.onRemoveFile}
              recommendations={recommendations}
              handleEditBudgetDetail={handleEditBudgetDetail}
              handleDeleteBudgetDetail={handleDeleteBudgetDetail}
              handleFileUpload={handleFileUpload}
              handleFileDownload={handleFileDownload}
              onUpdateForm={this.onUpdateForm}
              onUpdateError={this.onUpdateError}
              onSearchCoa={this.onSearchCoa}
              user={user}
              workflow={workflow}
            />
            { (workflow && workflow.length > 0) && (
            <div className="workflow_history">
              <h2>Worfklow</h2>
              {this.renderWorkflowHistory(workflow)}
            </div>
            )}
            {this.renderButton()}
          </ListForm>
        </form>
      </div>
    );
  }
}
ManageBudget.propTypes = {
  handleGetCoaOptions: PropTypes.func,
  handleGetBudgetDetail: PropTypes.func,
  handleAddBudgetDetail: PropTypes.func,
  handleEditBudgetDetail: PropTypes.func,
  handleDeleteBudgetDetail: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFileDownload: PropTypes.func,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
};
ManageBudget.defaultProps = {
  handleGetCoaOptions: noop,
  handleGetBudgetDetail: noop,
  handleAddBudgetDetail: noop,
  handleEditBudgetDetail: noop,
  handleDeleteBudgetDetail: noop,
  handleFileUpload: noop,
  handleFileDownload: noop,
  user: null,
  history: null,
};
