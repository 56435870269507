import axios from 'axios';
import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';
import { errorAlert, successAlert } from '../../utils/alert.util';
import { formatNonCashJournalPayload } from '../../utils/middleware.util';

const { CancelToken } = axios;
let source;

export function get(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_NON_CASH_JOURNAL_GET({ ...payload }, '', options);
      return res;
    } catch (err) {
      errorAlert({
        title: 'alerts.form.save_failed.title',
        errorMessage: err.error,
        form: 'Jurnal Non Kas',
        value: err.message,
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function create(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_NON_CASH_JOURNAL_POST(formatNonCashJournalPayload(payload), '', options);
      successAlert({ title: 'Berhasil menyimpan data' });
      return res;
    } catch (err) {
      errorAlert({
        title: 'alerts.form.save_failed.title',
        errorMessage: err.error,
        form: 'Jurnal Non Kas',
        value: err.message,
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function update(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_NON_CASH_JOURNAL_PUT(formatNonCashJournalPayload(payload), '', options);
      successAlert({ title: 'Berhasil menyimpan data' });
      return res;
    } catch (err) {
      errorAlert({
        title: 'alerts.form.save_failed.title',
        errorMessage: err.error,
        form: 'Jurnal Non Kas',
        value: err.message,
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function del(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_NON_CASH_JOURNAL_DELETE({ ...payload }, '', options);
      successAlert({ title: 'Berhasil menyimpan data' });
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function post(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_NON_CASH_JOURNAL_PUT({ ...payload }, '/post', options);
      successAlert({ title: 'Berhasil menyimpan data' });
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
