import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import NonKasJournalForm from './components/NonKasJournalForm.component';
import { normalizeAmount } from '../../../../utils/transformer.util';
import { FINANCE_NON_KAS_JOURNAL_FORM_FIELDS } from '../../../../constants/finance/bookeeping.constant';
import Button from '../../../../components/base/Button/Button.component';

export default class ManageNonKasJournal extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onSearchUnit = this._onSearchUnit.bind(this);
    this._initializeState = this._initializeState.bind(this);
    this.state = {
      form: {
        value: {
          date: new Date().toJSON().slice(0, 10),
          details: [],
        },
        error: {
          details: [],
        },
      },
    };
  }

  componentDidMount() {
    this._initializeState();
  }

  async _initializeState() {
    const { handleGetCoa, location } = this.props;
    const { isEdit = false, data } = location.state;
    await this._onSearchUnit();
    /*await handleGetCoa({
      excludes: {
        groups: [11100, 11200, 11300, 11400],
      },
    });*/
    await handleGetCoa();
    if (isEdit) {
      await this._setForm(data.journal_number);
    }
  }

  async _setForm(journal_number) {
    const { coa = {}, handleGetNonKasJournal } = this.props;
    const { unitOptions } = this.state;

    try {
      const payload = await handleGetNonKasJournal({ journal_number });
      this.setState({
        form: {
          value: {
            journal_number: payload.journal_number,
            date: payload.date,
            details: map(payload.details, detail => ({
              code_of_account: {
                code: parseInt(detail.code_of_account, 10),
                title: coa.list.find(o => o.code.toString() === detail.code_of_account.toString()).title,
                type: 'account',
              },
              units_id: {
                id: detail.units_id,
                title: unitOptions.find(o => o.id === detail.units_id).title,
              },
              description: detail.description,
              debit: normalizeAmount(detail.debit),
              credit: normalizeAmount(detail.credit),
              type: detail.type,
            })),
          },
          error: {
            details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _onSearchUnit(keyword) {
    const { handleGetUnit } = this.props;
    const data = await handleGetUnit({ keyword });
    this.setState(prevState => ({
      ...prevState,
      unitOptions: data,
    }));
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;

        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _onAddList(fieldName, type) {
    const defaultState = {
      code_of_account: {},
      debit: type === 'K' ? null : 0,
      credit: type === 'D' ? null : 0,
      description: '',
      units_id: {},
      type,
    };
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {
              ...defaultState,
            },
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  _onDeleteList(idx, fieldName) {
    this.setState((prevState) => {
      const list = prevState.form.value[fieldName];
      const listError = prevState.form.error[fieldName];

      list.splice(idx, 1);
      return {
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [...list],
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [...listError],
          },
        },
      };
    });
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  async _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleCreateNonKasJournal,
      handleUpdateNonKasJournal,
      history,
    } = this.props;
    let data = {};
    if (form.value.journal_number) {
      data = await handleUpdateNonKasJournal(form.value, history.goBack);
    } else {
      data = await handleCreateNonKasJournal(form.value, history.goBack);
    }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          journal_number: data.journal_number,
        },
      },
    }), () => { history.goBack() });
  }

  render() {
    const { form, unitOptions } = this.state;
    const { coa } = this.props;
    const { details } = form.value;
    const { details: detailsErrors } = form.error;
    return (
      <div className="manage-non-kas-journal">
        <form onSubmit={this._onSubmit}>
          <ListForm
            form={form}
            formFields={FINANCE_NON_KAS_JOURNAL_FORM_FIELDS}
            onFormChange={this._onFormChange}
          >
            <NonKasJournalForm
              fieldName="details"
              coa={coa}
              error={detailsErrors}
              list={details}
              onAddList={this._onAddList}
              onDeleteList={this._onDeleteList}
              onChange={this._onFormChange}
              unitOptions={unitOptions}
            />
          </ListForm>
          <div className="manage-non-kas-journal">
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
ManageNonKasJournal.propTypes = {
  handleCreateNonKasJournal: PropTypes.func.isRequired,
  handleUpdateNonKasJournal: PropTypes.func.isRequired,
  handleGetNonKasJournal: PropTypes.func.isRequired,
  handleGetCoa: PropTypes.func.isRequired,
  handleGetUnit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
};
ManageNonKasJournal.defaultProps = {};
