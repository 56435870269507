import language from '../languages';
import homeIcon from '../assets/img/menu/beranda.png';
import financeIcon from '../assets/img/menu/penganggaran.png';
import settingIcon from '../assets/img/menu/pengaturan.png';

export const SIDEBAR__DASHBOARD_MENU = [{
  title: language.translate.SIDEBAR__HOME,
  iconImage: homeIcon,
  path: '/dashboard',
}, {
  title: language.translate.SIDEBAR__FINANCE,
  iconImage: financeIcon,
  path: '/dashboard/keuangan',
  menu: [{
    title: language.translate.SIDEBAR__FINANCE__BUDGETING,
    icon: 'icon-minus',
    className: 'menu__left-icon--small',
    padding: 20,
    path: '/dashboard/keuangan/penganggaran',
    menu: [{
      title: language.translate.SIDEBAR__FINANCE__BUDGETING__BUDGET,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/penganggaran/anggaran',
    }, {
      title: language.translate.SIDEBAR__FINANCE__BUDGETING__NON_BUDGET,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/penganggaran/non-anggaran',
    }, /* {
      title: language.translate.SIDEBAR__FINANCE__BUDGETING__BUDGET_TRANSFER,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/penganggaran/pengalihan-anggaran',
    }, */{
      title: language.translate.SIDEBAR__FINANCE__BUDGETING__FUND_REQUEST,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/penganggaran/permohonan-dana',
    }, {
      title: language.translate.SIDEBAR__FINANCE__BUDGETING__REALIZATION,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/penganggaran/realisasi',
    }, {
      title: language.translate.SIDEBAR__FINANCE__BUDGETING__RECAPS,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/penganggaran/rekapitulasi',
      permission: 'view_budget_recaps',
    }, {
      title: language.translate.SIDEBAR__FINANCE__BUDGETING__RECAPS_GLOBAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/penganggaran/rekapitulasi-global',
      permission: 'view_budget_recaps_global',
    }],
  }, {
    title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING,
    icon: 'icon-minus',
    className: 'menu__left-icon--small',
    padding: 20,
    path: '/dashboard/keuangan/pembukuan',
    menu: [{
      title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__BALANCE,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/pembukuan/saldo-awal',
    }, {
      title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__CASH_JOURNAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/pembukuan/jurnal-kas',
      attributes: {
        journal_type: 'KAS',
      },
    }, {
      title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__BANK_JOURNAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/pembukuan/jurnal-bank',
      attributes: {
        journal_type: 'BANK',
      },
    }, {
      title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__H2H_JOURNAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/pembukuan/jurnal-h2h',
      attributes: {
        journal_type: 'H2H',
      },
    }, {
      title: 'Jurnal Kas Penutup',
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/pembukuan/jurnal-kas-penutup',
      attributes: {
        journal_type: 'KAS',
      },
    }, {
      title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__ADJUSTMENT_JOURNAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/pembukuan/jurnal-penyesuaian',
      permission: 'adjust_journal',
    }, {
      title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__CLOSING_JOURNAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/pembukuan/jurnal-penutup',
    }, {
      title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__NON_KAS_JOURNAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/pembukuan/jurnal-non-kas',
    }, {
      title: language.translate.SIDEBAR__FINANCE__BOOKKEEPING__PAYROLL_JOURNAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/pembukuan/jurnal-penggajian',
    },
    ],
  }, {
    title: language.translate.SIDEBAR__FINANCE__REPORT,
    icon: 'icon-minus',
    className: 'menu__left-icon--small',
    padding: 20,
    menu: [{
      title: language.translate.SIDEBAR__FINANCE__REPORT__PAYMENT,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/pembayaran-uang-sekolah',
    }, {
      title: language.translate.SIDEBAR__FINANCE__REPORT__PAYMENT_RECAP,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/rekap-pembayaran',
    }, {
      title: language.translate.SIDEBAR__FINANCE__REPORT__CASH_JOURNAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/jurnal-kas',
    }, {
      title: language.translate.SIDEBAR__FINANCE__REPORT__BANK_JOURNAL,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/jurnal-bank',
    }, {
      title: language.translate.SIDEBAR__FINANCE__REPORT__BIG,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/buku-besar',
    }, /* {
      title: language.translate.SIDEBAR__FINANCE__REPORT__MONTH,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/transaksi-bulanan',
    }, {
      title: language.translate.SIDEBAR__FINANCE__REPORT__DAILY_MONTH,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/jurnal-harian-dan-bulanan',
    }, */{
      title: language.translate.SIDEBAR__FINANCE__REPORT__PROFIT_AND_LOST,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/laba-rugi',
    }, {
      title: language.translate.SIDEBAR__FINANCE__REPORT__BALANCE,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/neraca',
    }, {
      title: language.translate.SIDEBAR__FINANCE__REPORT__SUPERVISION,
      icon: 'icon-angle-right',
      className: 'menu__left-icon--small',
      padding: 40,
      path: '/dashboard/keuangan/laporan/supervisi',
    }],
  }],

}, {
  title: language.translate.SIDEBAR__SETTING,
  iconImage: settingIcon,
  menu: [{
    title: 'Ganti Kata Sandi',
    path: '/dashboard/pengaturan/ganti-kata-sandi',
  }],
}];

export const SIDEBAR_ADMISSION_MENU = [{
  title: language.translate.SIDEBAR__HOME,
  iconImage: homeIcon,
  path: '/admission',
}];
