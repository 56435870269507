import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { KLAPPER_TABLE_BY_NIK_FIELDS } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';

export default class Klapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {},
        error: {},
      },
      filtered: false,
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
  }

  _handleFormChange(event) {
    const {
      name,
      value,
    } = event.target;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: '',
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ['year']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  render() {
    const { filtered, form } = this.state;
    const { handleGetKlapper, handleDeleteKlapper } = this.props;
    return (
      <div className="klapper">
        {
          !filtered ? (
            <StudentReportFilter
              year={form.value.year}
              error={form.error.year}
              onSearch={this._onSearch}
              onChange={this._handleFormChange}
            />
          ) : (
            <div className="klapper__content">
              <div className="klapper__content-header">
                <h1>SMK --</h1>
                <h3>Klapper Siswa Periode Tahun Ajaran 2016 / 2017</h3>
              </div>
              <ContentTable
                showActions={false}
                onSearchContent={handleGetKlapper}
                tableFields={KLAPPER_TABLE_BY_NIK_FIELDS}
                onClickDelete={handleDeleteKlapper}
              />
            </div>
          )
        }
      </div>
    );
  }
}
Klapper.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
Klapper.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
