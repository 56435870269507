import { connect } from 'react-redux';
import SupervisionReport from '../../../views/Finance/Report/Supervision.report.view';
import { getSupervisionReport } from '../../../states/thunks/report.thunk';
import { getSupervisionOptions } from '../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetSupervisionReport: payload => dispatch(getSupervisionReport(payload)),
    handleGetSupervisionOptions: payload => dispatch(getSupervisionOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupervisionReport);
