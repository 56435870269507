import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import classnames from 'classnames';
import {
  noop, isEqual,
} from 'lodash';
import { Button } from '../base';

const MySwal = withReactContent(Swal);

class NotificationsLegacy extends Component {
  constructor(props) {
    super(props);
    this._setContent = this._setContent.bind(this);
    this._renderContent = this._renderContent.bind(this);
    this._redirect = this._redirect.bind(this);
    this._showModal = this._showModal.bind(this);
    this._renderModalContent = this._renderModalContent.bind(this);
    this._onAcceptReconciliation = this._onAcceptReconciliation.bind(this);
    this.state = {
      data: [],
      hasChanges: false,
    };
  }

  componentDidMount() {
    this._setContent();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState(prevState => ({
        ...prevState,
        hasChanges: true,
      }), () => this._setContent());
    }
  }

  _showModal(id, data) {

    MySwal.fire({
      title: <p>Pilih tipe jurnal untuk rekonsiliasi</p>,
      html: this._renderModalContent(id, data),
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText:
      'Batal',
    });
  }

  async _onAcceptReconciliation(journalType, id, data) {
    const {
      handleAcceptReconciliation = noop,
      updateNotificationStatus = noop, history } = this.props;
    const newJournal = await handleAcceptReconciliation({
      journal_type: journalType,
      data,
    });

    if(newJournal) {
      MySwal.close();
      const res = await updateNotificationStatus({
        id
      });
      history.push(`/dashboard/keuangan/pembukuan/kelola-jurnal-${journalType.toLowerCase()}`, { isEdit: true, data: newJournal });
    }
  }

  _renderModalContent(id, data) {
    return (
      <div className="modal-content">
        <Button
          title="BANK"
          onClick={() => this._onAcceptReconciliation('BANK', id, data)}
        /><br/>
        <Button
          title="KAS"
          onClick={() => this._onAcceptReconciliation('KAS', id, data)}
        />
      </div>
    );
  }

  _redirect(message) {
    const { history } = this.props;
    const { type, content, id } = message;
    const data = JSON.parse(content);
  }

  _setContent() {
    const { data } = this.props;
    this.setState(() => ({
      data,
    }));
  }

  _renderContent() {
    const { t } = this.props;
    const { data } = this.state;

    const items = [];

    data.forEach((item, index) => {
      let unit = '';
      let amount = '';
      let { subject } = item;
      try {
        const subjectArr = JSON.parse(item.subject);
        [subject, unit, amount] = subjectArr;
      } catch (err) {
        console.log(err);
      }

      items.push([
        <div className="notifications-item" key={`notifications-${item.id}`}>
          <div className="notifications-subject">
            {t(subject, { unit, amount })}
          </div>
        </div>,
      ]);
    });

    return items;
  }

  render() {
    const { visible = false } = this.props;
    const wrapperClassName = classnames([
      'notifications-wrapper' ,
      !visible ? 'hidden' : '',
    ]);
    return (
      <div className={wrapperClassName}>
        <div className="notifications-content">
          {this._renderContent()}
        </div>
      </div>
    );
  }
}

const Notifications = withTranslation()(NotificationsLegacy);
export default Notifications;
