import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../components/base';

const PayrollJournal = ({
  handleGetPayrollJournal, handlePostPayrollJournal, history, user,
}) => {
  const [data, setData] = useState([]);

  const getData = useCallback(() => {
    const loadData = async () => {
      const res = await handleGetPayrollJournal();
      setData(res);
    };

    loadData();
  }, []);

  const handlePost = useCallback((id) => {
    const postJournal = async (arg) => {
      await handlePostPayrollJournal(arg);
      getData();
    };

    postJournal(id);
  }, []);

  useEffect(() => {
    getData();
  }, [user.workingUnit]);

  return (
    <table className="table">
      <thead>
        <tr>
          <th width="10%">Tanggal</th>
          <th>Nomor Bukti</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {
          data && data.map(item => (
            <tr>
              <td width="10%">
                {item.date}
              </td>
              <td width="80%">
                {item.journal_number}
              </td>
              <td>
                <div className="table__actions">
                  <Button type="primary" title="View" onClick={() => { history.push(`/dashboard/keuangan/pembukuan/jurnal-penggajian/${item.id}`); }} />
                  {
                    !item.is_posted && (
                      <Button type="primary" title="Post" onClick={() => handlePost(item.id)} />
                    )
                  }
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

PayrollJournal.propTypes = {
  handleGetPayrollJournal: PropTypes.func.isRequired,
  handlePostPayrollJournal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default PayrollJournal;
