// import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';
import { errorAlert } from '../../utils/alert.util';
import { STUDENT_RESULT_MOCK, KLAPPER_RESULT_MOCK } from '../../constants/student.constant';

export function manageRegistration(payload = {}, push) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { period, desc } = payload;
      const requestBody = new FormData();
      requestBody.append('periode', period);
      requestBody.append('desc', desc);
      // await api.BUDGET(requestBody, '/head/add', { additional: ['lang', 'create_by'] });
      push('/admission/results');
    } catch (err) {
      errorAlert({
        title: 'Registrasi Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStudent() {
  return async () => {
    try {
      return {
        result: STUDENT_RESULT_MOCK,
        currentPage: 1,
        totalPage: 1,
        total: 1,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getKlapper() {
  return async () => {
    try {
      return {
        result: KLAPPER_RESULT_MOCK,
        currentPage: 1,
        totalPage: 1,
        total: 1,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function uploadStudent(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { file } = payload;
      const requestBody = new FormData();
      requestBody.append('file', file);
      // await api.FILE(requestBody, '/upload');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah File Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
