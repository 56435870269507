import { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Logout extends PureComponent {
  componentWillMount() {
    const { handleLogout, history } = this.props;
    handleLogout(history);
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  history: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};
