import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';

export default function RegistrationForm(props) {
  const { onFormChange, form } = props;
  return (
    <div className="manage-registration__custom-form">
      <div className="manage-registration__custom-form-row">
        <div className="manage-registration__custom-form-row__field">
          <Input
            type="text"
            name="namaSaudara1"
            label="Nama Lengkap"
            onChange={onFormChange}
            value={form.value.namaSaudara1 || ''}
            error={form.error.namaSaudara1 || ''}
          />
        </div>
        <div className="manage-registration__custom-form-row__field">
          <Input
            type="text"
            name="tempatLahirSaudara1"
            label="Tempat / Tanggal Lahir"
            onChange={onFormChange}
            value={form.value.tempatLahirSaudara1 || ''}
            error={form.error.tempatLahirSaudara1 || ''}
          />
          <Input
            type="date"
            name="tanggalLahirSaudara1"
            label="&nbsp;"
            onChange={onFormChange}
            value={form.value.tanggalLahirSaudara1 || ''}
            error={form.error.tanggalLahirSaudara1 || ''}
          />
        </div>
        <div className="manage-registration__custom-form-row__field">
          <Select
            name="jenisKelaminSodara1"
            label="Jenis Kelamin"
            onChange={onFormChange}
            data={GENDER}
            placeholder="Pilih Jenis Kelamin"
            value={form.value.jenisKelaminSodara1 || ''}
            error={form.error.jenisKelaminSodara1 || ''}
          />
        </div>
      </div>
    </div>
  );
}
RegistrationForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
RegistrationForm.defaultProps = {
  onFormChange: noop,
};
