import { connect } from 'react-redux';
import Realization from '../../../../views/Finance/Budgeting/Realization/Realization.finance.view';
import { getRealization } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetRealization: payload => dispatch(getRealization(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Realization);
