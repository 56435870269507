import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';
import { errorAlert, successAlert } from '../../utils/alert.util';
import {
  manageInformationLetterFormat,
  manageInformationLetterFormatST,
  managePotonganFormat,
  manageStaffFormat,
  managePayrollFormat,
} from '../../utils/middleware.util';

export function manageInformationLetter(payload = {}, goBack, isEdit) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      // console.log(payload);
      if (isEdit) {
        await api.SK_POST(manageInformationLetterFormat({ created_by: user.id, ...payload },
          isEdit),
        '/edit');
      } else {
        await api.SK_POST(manageInformationLetterFormat({ created_by: user.id, ...payload },
          isEdit),
        '/create');
      }
      goBack();
    } catch (err) {
      errorAlert({
        title: isEdit
          ? 'Edit Surat Keterangan Gagal'
          : 'Tambah Surat Keterangan Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
export function manageInformationLetterST(payload = {}, goBack, isEdit) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      // console.log(payload);
      if (isEdit) {
        await api.ST_POST(manageInformationLetterFormatST({ created_by: user.id, ...payload },
          isEdit),
        '/edit');
      } else {
        await api.ST_POST(manageInformationLetterFormatST({ created_by: user.id, ...payload },
          isEdit),
        '/create');
      }
      goBack();
    } catch (err) {
      errorAlert({
        title: isEdit
          ? 'Edit Surat Tugas Gagal'
          : 'Tambah Surat Tugas Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteInformationLetter(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.SK_POST(payload, '/delete');
      return payload.id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Surat Keterangan Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function unpostingInformationLetter(payload = {},goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.SK_POST(payload, '/unposting');
      successAlert({ title: 'Unposting Berhasil' });
      //dispatch(commonActions.setLoading({ show: false }));
      //window.location.reload()
      goBack();
      //return payload.id;
    } catch (err) {
      errorAlert({
        title: 'Unposting Surat Keterangan Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function unpostingAssignmentLetter(payload = {},goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.ST_POST(payload, '/unposting');
      successAlert({ title: 'Unposting Berhasil' });
      goBack();
      //return payload.id;
    } catch (err) {
      errorAlert({
        title: 'Unposting Surat Tugas Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageRegistration(payload = {}, push) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { period, desc } = payload;
      const requestBody = new FormData();
      requestBody.append('periode', period);
      requestBody.append('desc', desc);
      // await api.BUDGET(requestBody, '/head/add', { additional: ['lang', 'create_by'] });
      push('/admission/results');
    } catch (err) {
      errorAlert({
        title: 'Registrasi Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStaff(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const { page = 1 } = payload;
      const requestBody = new FormData();
      // const unit_id = payload.unit_id;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, school_unit } = user;
      let unit_kerja = school_unit.id;
      if (workingUnit && workingUnit.id) {
        unit_kerja = workingUnit.id;
      }

      payload.unit_kerja = unit_kerja;
      console.log(unit_kerja, workingUnit);
      requestBody.append('page', page);
      requestBody.append('unit_kerja', unit_kerja);
      const res = await api.STAFF_POST(payload, '/list', {
        additional: ['lang'],
      });
      return {
        result: res.result.data,
        currentPage: res.result.current_page,
        totalPage: res.result.last_page,
        total: res.result.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getStaffById(params) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { nip } = params;
      const res = await api.STAFF_POST({ nip }, '/view');
      // res.result.status_kepegawaian = res.result.status_kepegawaian.id_status_kepegawaian;
      // res.result.jenis_kelamin = res.result.jenis_kelamin;
      // console.log(res.result)

      return res.result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPayrollById(params) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { nip } = params;
      const res = await api.PAYROLL_POST({ nip }, '/view');
      return res.result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function managePayroll(payload = {}, goBack, isEdit) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      if (isEdit) {
        await api.PAYROLL_POST(managePayrollFormat(payload, isEdit), '/edit');
      } else {
        // console.log("cek create staff");
        // console.log(payload);
        await api.PAYROLL_POST(managePayrollFormat(payload, isEdit), '/create');
      }
      goBack();
    } catch (err) {
      errorAlert({
        title: isEdit ? 'Edit Payroll Gagal' : 'Tambah Payroll Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getGajiRecaps(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.PAYROLL_POST(payload, '/slip');
      return res.result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function managePotongan(payload = {}, goBack, isEdit) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      if (isEdit) {
        await api.PAYROLL_POST(managePotonganFormat(payload, isEdit), '/edit');
      } else {
        // console.log("cek api managepotongan");
        // console.log(payload);
        await api.PAYROLL_POST(managePotonganFormat(payload, isEdit),
          '/potongan');
      }
      goBack();
    } catch (err) {
      errorAlert({
        title: isEdit ? 'Edit Potongan Gagal' : 'Tambah Potongan Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageStaff(payload = {}, goBack, isEdit) {
  //console.log(payload)
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      if (isEdit) {
        await api.STAFF_POST(manageStaffFormat(payload, isEdit), '/edit');
      } else {
        // console.log("cek create staff");
        //console.log(payload);
        await api.STAFF_POST(manageStaffFormat(payload, isEdit), '/create');
      }
      successAlert({ title: 'Data Berhasil Tersimpan' });
      goBack();
    } catch (err) {
      errorAlert({
        title: isEdit ? 'Edit Staff Gagal' : 'Tambah Staff Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteStaff(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.STAFF_POST(payload, '/delete');
      return res;
      // await api.STAFF_POST(payload, '/delete');
      // return payload.id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Staff Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function uploadStaff(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { file } = payload;
      const requestBody = new FormData();
      requestBody.append('file', file);
      // await api.FILE(requestBody, '/upload');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah File Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getJobApplication() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getInformationLetter(params) {
  return async () => {
    try {
      const { nip } = params;
      const res = await api.SK_POST({ nip }, '/list');
      // console.log(res);
      return {
        result: res.result,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getRequest() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getDiscontinuance() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getDiscontinuanceDescription() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getAssignment() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getPayroll(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const requestBody = new FormData();
      requestBody.append('page', page);
      // const res = await api.PAYROLL_POST({}, '/list');
      const res = await api.PAYROLL_POST(requestBody, '/list', {
        additional: ['lang'],
      });
      return {
        result: res.result.data,
        currentPage: res.result.current_page,
        totalPage: res.result.last_page,
        total: res.result.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getPayrollList(payload = {}) {
  // console.log(payload)
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.PAYROLL_POST(payload, '/list');
      return {
        result: res.result.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPayrollData(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.PAYROLL_POST(payload, '/view');
      return res.result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function storePayrollData(payload = {}, goBack, isEdit) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      // console.log(payload);
      if (isEdit) {
        // await api.SK_POST(
        //   managePayrollFormat(
        //     { created_by: user.id, ...payload },
        //     isEdit
        //   ),
        //   "/edit"
        // );
      } else {
        await api.PAYROLL_POST(managePayrollFormat({ ...payload }, isEdit),
          '/storedetail');
      }
      goBack();
    } catch (err) {
      console.log(err);
      errorAlert({
        title: isEdit
          ? 'Edit Input Payroll Gagal'
          : 'Input Payroll Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getRetire() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getPromotion() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getEmployeeContract() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getTeacherContract() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getPosition() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getPermanent() {
  return async () => {
    try {
      return Promise.resolve({
        result: [],
        currentPage: 1,
        totalPage: 1,
        total: 1,
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getPayrollReportHeader() {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.PAYROLL_POST({}, '/headerreportpayroll');
      return res.result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPayrollReport(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, school_unit = {} } = user;
      let unit_id = school_unit ? school_unit.va_code : [];

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.attributes.va_code;
      }
      // payload['unit_id'] = unit_id[0].id;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.PAYROLL_POST(payload, '/reportpayroll');
      return res.result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPotonganInternList(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.INTERN(payload, '/list');
      return res.result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function postPotonganIntern(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.INTERN(payload, '/posting');
      return res.result;
    } catch (err) {
      errorAlert({
        title: 'Gagal memposting data',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function createPotonganIntern(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.INTERN(payload, '/create');
      return res.result;
    } catch (err) {
      errorAlert({
        title: 'Gagal menyimpan data',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
