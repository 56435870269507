import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ListForm from '../../components/ListForm/ListForm.component';
import RegistrationForm from './components/RegistrationForm.component';
import { commaFormatted } from '../../utils/transformer.util';
import { STUDENT_FORM } from '../../constants/student.constant';
import { Button } from '../../components/base/index';

export default class ManageRegistration extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this.state = {
      page: 1,
      form: {
        value: {},
        error: {},
      },
    };
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page - 1,
    }));
  }

  _onSubmit() {
    const { form } = this.state;
    const { handleManageRegistration, history } = this.props;
    handleManageRegistration(form.value, history.push);
  }

  render() {
    const { form, page } = this.state;
    return (
      <div className="manage-registration">
        <form onSubmit={this._onSubmit}>
          {
            (page === 1 || page === 2) ? (
              <ListForm
                form={form}
                formFields={STUDENT_FORM[page]}
                onFormChange={this._onFormChange}
              />
            ) : (
              <RegistrationForm
                form={form}
                onFormChange={this._onFormChange}
              />
            )
          }
          <div className="manage-registration__footer">
            <div className="manage-registration__button-wrapper">
              {
                page > 1 && (
                  <Button
                    onClick={this._onClickBack}
                    title="Kembali"
                  />
                )
              }
              {
                page === 3 ? (
                  <Button
                    onClick={this._onSubmit}
                    title="Simpan"
                  />
                ) : (
                  <Button
                    onClick={this._onClickNext}
                    title="Next"
                  />
                )
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}
ManageRegistration.propTypes = {
  handleManageRegistration: PropTypes.func,
  history: PropTypes.object.isRequired,
};
ManageRegistration.defaultProps = {
  handleManageRegistration: noop,
};
