const STUDENT_PAGE_1_FORM_FIELDS = [{
  type: 'title',
  text: 'Data Pribadi Siswa',
  full: true,
}, {
  type: 'text',
  name: 'nis',
  label: 'NIS / NIM',
  placeholder: '',
}, {
  type: 'text',
  name: 'namaLengkap',
  label: 'Nama Lengkap',
  placeholder: '',
}, {
  type: 'text',
  name: 'namaPanggilan',
  label: 'Nama Panggilan',
  placeholder: '',
}, {
  type: 'select',
  name: 'jenisKelamin',
  label: 'Jenis Kelamin',
  placeholder: '',
  data: [
    { label: 'Laki-Laki', value: 'lakilaki' },
    { label: 'Perempuan', value: 'perempuan' },
  ],
}, {
  type: 'dualInput',
  leftName: 'tinggiBadan',
  leftLabel: 'Tinggi Badan/ Berat Badan',
  leftPlaceholder: '',
  rightName: 'beratBadan',
  rightLabel: '\u00A0',
  rightPlaceholder: '',
}, {
  type: 'dualInput',
  leftName: 'tempat',
  leftLabel: 'Tempat / Tanggal Lahir',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalLahir',
  rightLabel: '\u00A0',
}, {
  type: 'select',
  name: 'golonganDarah',
  label: 'Golongan Darah',
  placeholder: 'Pilih Golongan Darah',
  data: [
    { label: 'A', value: 'a' },
    { label: 'B', value: 'b' },
    { label: 'O', value: 'o' },
    { label: 'AB', value: 'ab' },
  ],
}, {
  type: 'text',
  name: 'hobi',
  label: 'Hobi',
  placeholder: '',
}, {
  type: 'text',
  name: 'penyakit',
  label: 'Penyakit dan Kelainan (bila ada)',
  placeholder: '',
}, {
  type: 'select',
  name: 'agama',
  label: 'Agama',
  placeholder: 'Pilih Agama',
  data: [
    { label: 'Budha', value: 'budha' },
    { label: 'Hindu', value: 'hindu' },
    { label: 'Katolik', value: 'katolik' },
    { label: 'Kristen', value: 'kristen' },
    { label: 'Muslim', value: 'muslim' },
  ],
}, {
  type: 'text',
  name: 'kewarganegaraan',
  label: 'Kewarganegaraan',
  placeholder: '',
}, {
  type: 'text',
  name: 'anakKe',
  label: 'Anak Ke',
  placeholder: '',
}, {
  type: 'text',
  inputType: 'number',
  name: 'jumlahSaudaraKandung',
  label: 'Jumlah Saudara Kandung',
  placeholder: '',
}, {
  type: 'text',
  name: 'bahasa',
  label: 'Bahasa Yang Digunakan Sehari-hari',
  placeholder: '',
}, {
  type: 'text',
  name: 'statusAnak',
  label: 'Status Anak',
  placeholder: '',
}, {
  type: 'text',
  name: 'email',
  label: 'Email',
  placeholder: '',
}, {
  type: 'text',
  name: 'alamat',
  label: 'Alamat Tempat Tinggal',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelurahan',
  label: 'Kelurahan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kecamatan',
  label: 'Kecamatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kabupaten',
  label: 'Kotamadya / Kabupaten',
  placeholder: '',
}, {
  type: 'text',
  name: 'propinsi',
  label: 'Propinsi',
  placeholder: '',
}, {
  type: 'text',
  name: 'noTelp',
  label: 'Nomor Telepon',
  placeholder: '',
}, {
  type: 'text',
  name: 'statusRumah',
  label: 'Status Kepemilikan Rumah',
  placeholder: '',
}, {
  type: 'text',
  name: 'jarak',
  label: 'Jarak Sekolah - Rumah',
  placeholder: '',
}, {
  type: 'option',
  name: 'unit',
  label: 'Unit',
  data: [
    { label: 'TK', value: 'tk' },
    { label: 'SD', value: 'sd' },
    { label: 'SMP', value: 'smp' },
    { label: 'SMA', value: 'sma' },
  ],
}, {
  type: 'text',
  name: 'tahun',
  label: 'Tahun Ajaran',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelas',
  label: 'Kelas',
  placeholder: '',
}, {
  type: 'empty',
}, {
  type: 'title',
  text: 'Asal Sekolah / Sekolah Sebelumnya',
  full: true,
}, {
  type: 'text',
  name: 'namaSekolah',
  label: 'Nama Sekolah',
  placeholder: '',
}, {
  type: 'text',
  name: 'alamatSekolah',
  label: 'Alamat Sekolah',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelurahanSekolah',
  label: 'Kelurahan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kecamatanSekolah',
  label: 'Kecamatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'propinsiSekolah',
  label: 'Propinsi',
  placeholder: '',
}, {
  type: 'text',
  name: 'kabupatenSekolah',
  label: 'Kotamadya / Kabupaten',
  placeholder: '',
}, {
  type: 'text',
  name: 'noTelpSekolah',
  label: 'Nomor Telepon',
  placeholder: '',
}, {
  type: 'text',
  name: 'alasan',
  label: 'Alasan Pindah',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelasSekolah',
  label: 'Kelas',
  placeholder: '',
}, {
  type: 'dualInput',
  leftName: 'noSurat',
  leftLabel: 'Nomor Surat Pindah / Tanggal',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalPindah',
  rightLabel: '\u00A0',
}, {
  type: 'title',
  text: 'Ijazah',
  full: true,
}, {
  type: 'dualInput',
  leftName: 'noIjazah',
  leftLabel: 'Nomor / Tanggal',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalIjazah',
  rightLabel: '\u00A0',
}, {
  type: 'dualInput',
  leftName: 'nilai',
  leftLabel: 'Nilai / Jumlah Pelajaran',
  leftPlaceholder: '',
  rightName: 'jumlahPelajaran',
  rightLabel: '\u00A0',
  rightPlaceholder: '',
}, {
  type: 'text',
  name: 'namaOrtu',
  label: 'Nama Orang Tua (Sesuai Ijazah)',
  placeholder: '',
}, {
  type: 'empty',
}, {
  type: 'title',
  text: 'SHUN',
  full: true,
}, {
  type: 'dualInput',
  leftName: 'noShun',
  leftLabel: 'Nomor / Tanggal',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalShun',
  rightLabel: '\u00A0',
}, {
  type: 'dualInput',
  leftName: 'nilaiShun',
  leftLabel: 'Nilai / Jumlah Pelajaran',
  leftPlaceholder: '',
  rightName: 'jumlahPelajaranShun',
  rightLabel: '\u00A0',
  rightPlaceholder: '',
}, {
  type: 'empty',
}, {
  type: 'title',
  text: '\u00A0',
  full: true,
}, {
  type: 'text',
  name: 'noUan',
  label: 'Nomor Peserta UAN',
  placeholder: '',
}, {
  type: 'empty',
}];


/*
ORTU
*/
const STUDENT_PAGE_2_FORM_FIELDS = [{
  type: 'title',
  text: 'Data Orang Tua / Wali',
  full: true,
}, { // AYAH
  type: 'title',
  text: 'Ayah',
  full: true,
}, {
  type: 'text',
  name: 'namaLengkapAyah',
  label: 'Nama Lengkap',
  placeholder: '',
}, {
  type: 'text',
  name: 'nikAyah',
  label: 'NIK',
  placeholder: '',
}, {
  type: 'select',
  name: 'agamaAyah',
  label: 'Agama',
  placeholder: 'Pilih Agama',
  data: [
    { label: 'Budha', value: 'budha' },
    { label: 'Hindu', value: 'hindu' },
    { label: 'Katolik', value: 'katolik' },
    { label: 'Kristen', value: 'kristen' },
    { label: 'Muslim', value: 'muslim' },
  ],
}, {
  type: 'text',
  name: 'kewarganegaraanAyah',
  label: 'Kewarganegaraan',
  placeholder: '',
}, {
  type: 'text',
  name: 'pendidikanAkhirAyah',
  label: 'Pendidikan Terakhir',
  placeholder: '',
}, {
  type: 'dualInput',
  leftName: 'tempatLahirAyah',
  leftLabel: 'Tempat / Tanggal Lahir',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalLahirAyah',
  rightLabel: '\u00A0',
}, {
  type: 'text',
  name: 'StatusAyah',
  label: 'Status / Kondisi',
  placeholder: '',
}, {
  type: 'text',
  name: 'alamatAyah',
  label: 'Alamat Tempat Tinggal',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelurahanAyah',
  label: 'Kelurahan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kecamatanAyah',
  label: 'Kecamatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kabupatenAyah',
  label: 'Kotamadya / Kabupaten',
  placeholder: '',
}, {
  type: 'text',
  name: 'propinsiAyah',
  label: 'Propinsi',
  placeholder: '',
}, {
  type: 'text',
  name: 'noTelpAyah',
  label: 'Nomor Telepon / HP',
  placeholder: '',
}, {
  type: 'text',
  name: 'pekerjaanAyah',
  label: 'Pekerjaan',
  placeholder: '',
}, {
  type: 'text',
  name: 'jabatanAyah',
  label: 'Jabatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'penghasilanAyah',
  label: 'Penghasilan',
  placeholder: '',
}, { // IBU
  type: 'title',
  text: 'Ibu',
  full: true,
}, {
  type: 'text',
  name: 'namaLengkapIbu',
  label: 'Nama Lengkap',
  placeholder: '',
}, {
  type: 'text',
  name: 'nikIbu',
  label: 'NIK',
  placeholder: '',
}, {
  type: 'select',
  name: 'agamaIbu',
  label: 'Agama',
  placeholder: 'Pilih Agama',
  data: [
    { label: 'Budha', value: 'budha' },
    { label: 'Hindu', value: 'hindu' },
    { label: 'Katolik', value: 'katolik' },
    { label: 'Kristen', value: 'kristen' },
    { label: 'Muslim', value: 'muslim' },
  ],
}, {
  type: 'text',
  name: 'kewarganegaraanIbu',
  label: 'Kewarganegaraan',
  placeholder: '',
}, {
  type: 'text',
  name: 'pendidikanAkhirIbu',
  label: 'Pendidikan Terakhir',
  placeholder: '',
}, {
  type: 'dualInput',
  leftName: 'tempatLahirIbu',
  leftLabel: 'Tempat / Tanggal Lahir',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalLahirIbu',
  rightLabel: '\u00A0',
}, {
  type: 'text',
  name: 'StatusIbu',
  label: 'Status / Kondisi',
  placeholder: '',
}, {
  type: 'text',
  name: 'alamatIbu',
  label: 'Alamat Tempat Tinggal',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelurahanIbu',
  label: 'Kelurahan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kecamatanIbu',
  label: 'Kecamatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kabupatenIbu',
  label: 'Kotamadya / Kabupaten',
  placeholder: '',
}, {
  type: 'text',
  name: 'propinsiIbu',
  label: 'Propinsi',
  placeholder: '',
}, {
  type: 'text',
  name: 'noTelpIbu',
  label: 'Nomor Telepon / HP',
  placeholder: '',
}, {
  type: 'text',
  name: 'pekerjaanIbu',
  label: 'Pekerjaan',
  placeholder: '',
}, {
  type: 'text',
  name: 'jabatanIbu',
  label: 'Jabatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'penghasilanIbu',
  label: 'Penghasilan',
  placeholder: '',
}, { // WALI
  type: 'title',
  text: 'Wali',
  full: true,
}, {
  type: 'text',
  name: 'namaLengkapWali',
  label: 'Nama Lengkap',
  placeholder: '',
}, {
  type: 'text',
  name: 'nikWali',
  label: 'NIK',
  placeholder: '',
}, {
  type: 'select',
  name: 'agamaWali',
  label: 'Agama',
  placeholder: 'Pilih Agama',
  data: [
    { label: 'Budha', value: 'budha' },
    { label: 'Hindu', value: 'hindu' },
    { label: 'Katolik', value: 'katolik' },
    { label: 'Kristen', value: 'kristen' },
    { label: 'Muslim', value: 'muslim' },
  ],
}, {
  type: 'text',
  name: 'kewarganegaraanWali',
  label: 'Kewarganegaraan',
  placeholder: '',
}, {
  type: 'text',
  name: 'pendidikanAkhirWali',
  label: 'Pendidikan Terakhir',
  placeholder: '',
}, {
  type: 'dualInput',
  leftName: 'tempatLahirWali',
  leftLabel: 'Tempat / Tanggal Lahir',
  leftPlaceholder: '',
  rightType: 'date',
  rightName: 'tanggalLahirWali',
  rightLabel: '\u00A0',
}, {
  type: 'text',
  name: 'StatusWali',
  label: 'Status / Kondisi',
  placeholder: '',
}, {
  type: 'text',
  name: 'alamatWali',
  label: 'Alamat Tempat Tinggal',
  placeholder: '',
}, {
  type: 'text',
  name: 'kelurahanWali',
  label: 'Kelurahan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kecamatanWali',
  label: 'Kecamatan',
  placeholder: '',
}, {
  type: 'text',
  name: 'kabupatenWali',
  label: 'Kotamadya / Kabupaten',
  placeholder: '',
}, {
  type: 'text',
  name: 'propinsiWali',
  label: 'Propinsi',
  placeholder: '',
}, {
  type: 'text',
  name: 'noTelpWali',
  label: 'Nomor Telepon / HP',
  placeholder: '',
}];

export const STUDENT_FORM = {
  1: STUDENT_PAGE_1_FORM_FIELDS,
  2: STUDENT_PAGE_2_FORM_FIELDS,
};

export const ADMISSION_REQUIRED_FIELD = ['parameter_code'];

export const STUDENT_TABLE_FIELDS = {
  label: [
    'No',
    'NIS / NIM',
    'Tanggal Lahir',
    'Tempat Lahir',
    'Nama Lengkap',
    'Alamat',
    'Action',
  ],
  value: [
    {},
    { type: 'string', attribute: 'nis' },
    { type: 'date', attribute: 'tanggalLahir' },
    { type: 'string', attribute: 'tempatLahir' },
    { type: 'string', attribute: 'namaSiswa' },
    { type: 'string', attribute: 'alamat' },
  ],
};

export const STUDENT_TABLE_BY_NIK_FIELDS = {
  label: [
    'No',
    'Nama',
    'L/P',
    'No. Induk',
    'Tempat Lahir',
    'Tanggal Lahir',
    'Agama',
    'Nama Orang Tua / Wali',
    'Alamat',
    'Kelas',
    'Tanggal Masuk',
  ],
  value: [
    {},
    { type: 'string', attribute: 'namaSiswa' },
    { type: 'string', attribute: 'jenisKelamin' },
    { type: 'string', attribute: 'nis' },
    { type: 'string', attribute: 'tempatLahir' },
    { type: 'date', attribute: 'tanggalLahir' },
    { type: 'string', attribute: 'agama' },
    { type: 'string', attribute: 'namaOrangtua' },
    { type: 'string', attribute: 'alamat' },
    { type: 'string', attribute: 'kelas' },
    { type: 'date', attribute: 'tanggalMasuk' },
  ],
};

export const KLAPPER_TABLE_BY_NIK_FIELDS = {
  label: [
    'No',
    'Nama Siswa',
    'Nomor Induk',
    'Tanggal Masuk',
    '16/17',
    '17/18',
    '18/19',
    '19/20',
    '20/21',
    'Keterangan',
  ],
  value: [
    {},
    { type: 'string', attribute: 'namaSiswa' },
    { type: 'string', attribute: 'nis' },
    { type: 'date', attribute: 'tanggalMasuk' },
    { type: 'string', attribute: '16' },
    { type: 'string', attribute: '17' },
    { type: 'string', attribute: '18' },
    { type: 'string', attribute: '19' },
    { type: 'string', attribute: '20' },
    { type: 'string', attribute: 'keterangan' },
  ],
};

export const STUDENT_RESULT_MOCK = [{
  nis: '1111',
  tanggalLahir: new Date(),
  tempatLahir: 'Jakarta',
  namaSiswa: 'Nicholas Handy',
  alamat: '67 Elbert Forest Suite 028',
  jenisKelamin: 'L',
  agama: 'Katolik',
  namaOrangtua: 'nugroho',
  kelas: 'X B/01',
  tanggalMasuk: new Date(),
}, {
  nis: '2222',
  tanggalLahir: new Date(),
  tempatLahir: 'Jakarta',
  namaSiswa: 'Bayu',
  alamat: '67 Elbert Forest Suite 100',
  jenisKelamin: 'L',
  agama: 'Katolik',
  namaOrangtua: 'nugroho',
  kelas: 'X B/01',
  tanggalMasuk: new Date(),
}, {
  nis: '333',
  tanggalLahir: new Date(),
  tempatLahir: 'Jakarta',
  namaSiswa: 'Chris',
  alamat: '67 Elbert Forest Suite 200',
  jenisKelamin: 'L',
  agama: 'Katolik',
  namaOrangtua: 'nugroho',
  kelas: 'X B/01',
  tanggalMasuk: new Date(),
}];

export const KLAPPER_RESULT_MOCK = [{
  nis: '1111',
  namaSiswa: 'Nicholas Handy',
  tanggalMasuk: new Date(),
  16: 'XB',
  17: '',
  18: '',
  19: '',
  20: '',
  keterangan: '',
}, {
  nis: '2222',
  namaSiswa: 'Bayu',
  tanggalMasuk: new Date(),
  16: 'XB',
  17: '',
  18: '',
  19: '',
  20: '',
  keterangan: '',
}, {
  nis: '333',
  namaSiswa: 'Chris',
  tanggalMasuk: new Date(),
  16: '',
  17: 'XA',
  18: '',
  19: '',
  20: '',
  keterangan: '',
}];

export const UPLOAD_STUDENT_FORM_FIELDS = [{
  type: 'file',
  name: 'file',
  label: 'File',
  placeholder: 'Pilih file',
  inputType: 'file',
  full: true,
}];

export const STUDENT_BOOK_MOCK = [{
  title: 'KETERANGAN TENTANG DATA DIRI SISWA',
  data: [{
    label: 'Nama Siswa',
    value: 'Agustinus Andi Hismanto',
    details: [{ label: 'Nama Lengkap', value: 'Agustinus Andi Hismanto' }, { label: 'Nama Panggilan', value: 'Andi' }],
  }, {
    label: 'Jenis Kelamin',
    value: 'Laki-laki',
  }, {
    label: 'Tempat dan Tanggal Lahir',
    value: 'Lampung, 27-05- 1981',
  }, {
    label: 'Agama',
    value: 'Katolik',
  }, {
    label: 'Kewarganegaraan',
    value: 'WNI',
  }, {
    label: 'Anak Ke Berapa',
    value: '1',
  }, {
    label: 'Jumlah Saudara Kandung',
    value: '4',
  }, {
    label: 'Jumlah Saudara Tiri',
    value: '-',
  }, {
    label: 'Jumlah Saudara Angkat',
    value: '-',
  }, {
    label: 'Anak',
    value: 'Lengkap Orang Tuanya',
  }, {
    label: 'Bahasa Sehari Hari',
    value: 'Indonesia',
  }],
}, {
  title: 'KETERANGAN TEMPAT TINGGAL',
  data: [{
    label: 'Alamat',
    value: 'Serpong garden blok a',
  }, {
    label: 'Nomor Telepon',
    value: '08123123123',
  }, {
    label: 'Tinggal Dengan\n(Orang tua / saudara / asrama /kos)',
    value: 'Orang Tua',
  }, {
    label: 'Jarak Tempat\n(tinggal - sekolah)',
    value: '15.00 km',
  }],
}, {
  title: 'KETERANGAN KESEHATAN',
  data: [{
    label: 'Golongan Darah',
    value: 'O',
  }, {
    label: 'Penyakir Yang Pernah Diderita (TBC / Cacar / Malaria / dsb)',
    value: '-',
  }, {
    label: 'Kelainan Jasmani',
    value: '-',
  }, {
    label: 'Tinggi Dan Berat Badan',
    value: '170cm 80kg',
  }],
}, {
  title: 'KETERANGAN PENDIDIKAN',
  data: [{
    label: 'Pendidikan Sebelumnya',
    value: '',
    details: [{
      label: 'Lulusan Dari',
      value: 'Santa Ursula BSD',
    }, {
      label: 'Tanggal dan Nomor STTB',
      value: '1123123',
    }, {
      label: 'Lama Belajar',
      value: '3 Tahun',
    }],
  }, {
    label: 'Pindahan',
    value: '',
    details: [{
      label: 'Dari Sekolah',
      value: '-',
    }, {
      label: 'Alasan',
      value: '-',
    }],
  }, {
    label: 'Diterima Di Sekolah Ini',
    value: '',
    details: [{
      label: 'Di Kelas',
      value: '1',
    }, {
      label: 'Program',
      value: '-',
    }, {
      label: 'Tanggal',
      value: '21-7-2010',
    }],
  }],
}, {
  title: 'KETERANGAN TENTANG AYAH KANDUNG',
  data: [{
    label: 'Nama',
    value: '-',
  }, {
    label: 'Tempat dan Tanggal Lahir',
    value: '-',
  }, {
    label: 'Agama',
    value: 'Katokik',
  }, {
    label: 'Kewarganegaraan',
    value: 'WNI',
  }, {
    label: 'Pendidikan',
    value: '-',
  }, {
    label: 'Pekerjaan',
    value: '-',
  }, {
    label: 'Penghasilan / Bulan',
    value: '-',
  }, {
    label: 'Alamat Rumah Dan Telepon',
    value: 'Serpong Garden Blok e',
  }, {
    label: 'Masih Hidup / Meninggal',
    value: 'Masih Hidup',
  }],
}, {
  title: 'KETERANGAN TENTANG IBU KANDUNG',
  data: [{
    label: 'Nama',
    value: '-',
  }, {
    label: 'Tempat dan Tanggal Lahir',
    value: '-',
  }, {
    label: 'Agama',
    value: 'Katokik',
  }, {
    label: 'Kewarganegaraan',
    value: 'WNI',
  }, {
    label: 'Pendidikan',
    value: '-',
  }, {
    label: 'Pekerjaan',
    value: '-',
  }, {
    label: 'Penghasilan / Bulan',
    value: '-',
  }, {
    label: 'Alamat Rumah Dan Telepon',
    value: 'Serpong Garden Blok e',
  }, {
    label: 'Masih Hidup / Meninggal',
    value: 'Masih Hidup',
  }],
}, {
  title: 'KETERANGAN TENTANG WALI',
  data: [{
    label: 'Nama',
    value: '-',
  }, {
    label: 'Tempat dan Tanggal Lahir',
    value: '-',
  }, {
    label: 'Agama',
    value: 'Katokik',
  }, {
    label: 'Kewarganegaraan',
    value: 'WNI',
  }, {
    label: 'Pendidikan',
    value: '-',
  }, {
    label: 'Pekerjaan',
    value: '-',
  }, {
    label: 'Penghasilan / Bulan',
    value: '-',
  }, {
    label: 'Alamat Rumah Dan Telepon',
    value: 'Serpong Garden Blok e',
  }, {
    label: 'Masih Hidup / Meninggal',
    value: 'Masih Hidup',
  }],
}, {
  title: 'KEGEMARAN SISWA',
  data: [{
    label: 'Kesenian',
    value: '-',
  }, {
    label: 'Olahraga',
    value: '-',
  }, {
    label: 'Kemasyarakatan',
    value: '-',
  }, {
    label: 'Lain-lain',
    value: '-',
  }],
}, {
  title: 'KETERANGAN PERKEMBANGAN SISWA',
  data: [{
    label: 'Menerima Beasiswa',
    value: '-',
  }, {
    label: 'Meninggalkan Sekolah',
    value: '',
    details: [{
      label: 'Tanggal Meninggalkan Sekolah',
      value: '-',
    }, {
      label: 'Alasa',
      value: '-',
    }],
  }, {
    label: 'Akhir Pendidikan',
    value: '',
    details: [{
      label: 'Tempat Belajar',
      value: '-',
    }, {
      label: 'STTB Nomor',
      value: '-',
    }],
  }],
}, {
  title: 'KETERANGAN SETELAH SELESAI PENDIDIKAN',
  data: [{
    label: 'Melanjutkan Di',
    value: '-',
  }, {
    label: 'Bekerja',
    value: '',
    details: [{
      label: 'Tanggal Mulai Bekerja',
      value: '-',
    }, {
      label: 'Nama Perusahaan / Lembaga',
      value: '-',
    }, {
      label: 'Penghasilan',
      value: '-',
    }],
  }],
}];
