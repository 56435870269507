import { connect } from 'react-redux';
import Klapper from '../../../views/Student/report/Klapper.student.view';
import { getKlapper } from '../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetKlapper: () => dispatch(getKlapper()),
  };
}

export default connect(null, mapDispatchToProps)(Klapper);
