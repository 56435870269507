import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, SearchSelect } from '../../../../../components/base';
import { commaFormatted } from '../../../../../utils/transformer.util';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';

export default class NonKasJournalForm extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._getTotal = this._getTotal.bind(this);
  }

  _onAddList(type) {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName, type);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  _getTotal(type) {
    const { list } = this.props;
    const sum = list.reduce((accumulator, currentValue) => {
      const nominal = type === 'K' ? currentValue.credit : currentValue.debit;
      return accumulator + nominal;
    }, 0);
    return commaFormatted(sum);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, coa, unitOptions, handleSearchUnit,
    } = this.props;
    return (
      <table className="manage-adjustment-journal__form-table table">
        <thead>
          <tr>
            <th rowSpan="2" width="2%">No.</th>
            <th colSpan="2">Akun</th>
            <th rowSpan="2">Keterangan</th>
            <th rowSpan="2" width="8%">Unit</th>
            <th rowSpan="2" width="10%">Debit</th>
            <th rowSpan="2" width="10%">Kredit</th>
            <th rowSpan="2" width="2%">&nbsp;</th>
          </tr>
          <tr>
            <th width="11%">Debit</th>
            <th width="11%">Kredit</th>
          </tr>
        </thead>
        <tbody>
          {
          map(list, (data, idx) => (
            <tr key={`adjustment_journal_data__${idx}`}>
              <td>{idx + 1}</td>
              <td>
                { (data.type === 'D') && (
                  <SelectCoa
                    noMargin
                    inputArray
                    arrayPosition={idx}
                    fieldName={fieldName}
                    name="code_of_account"
                    coa={coa}
                    onClick={onChange}
                    value={data.code_of_account}
                    error={error[idx] ? error[idx].code_of_account : ''}
                    rightIcon="icon-search"
                  />
                )}
              </td>
              <td>
                { (data.type === 'K') && (
                  <SelectCoa
                    noMargin
                    inputArray
                    arrayPosition={idx}
                    fieldName={fieldName}
                    name="code_of_account"
                    coa={coa}
                    onClick={onChange}
                    value={data.code_of_account}
                    error={error[idx] ? error[idx].code_of_account : ''}
                    rightIcon="icon-search"
                  />
                )}
              </td>
              <td>
                <Input
                  noMargin
                  data-input-array
                  data-array-position={idx}
                  data-field-name={fieldName}
                  name="description"
                  onChange={onChange}
                  value={data.description || ''}
                  error={error[idx] ? error[idx].description : ''}
                />
              </td>
              <td>
                <SearchSelect
                  disabled={false}
                  async={false}
                  name="units_id"
                  list={unitOptions}
                  fieldName={fieldName}
                  arrayPosition={idx}
                  inputArray
                  placeholder="Pilih Unit"
                  onSearch={handleSearchUnit}
                  onChange={onChange}
                  value={data.units_id}
                  error={error[idx] ? error[idx].units_id : ''}
                  labelName="title"
                  valueName="id"
                />
              </td>
              <td className="nominal">
                { (data.type === 'D') && (
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name={fieldName}
                    data-input-type="number"
                    name="debit"
                    onChange={onChange}
                    disabled={data.type === 'K'}
                    value={commaFormatted(data.debit) || ''}
                    error={error[idx] ? error[idx].debit : ''}
                  />
                )}
              </td>
              <td className="nominal">
                { (data.type === 'K') && (
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name={fieldName}
                    data-input-type="number"
                    name="credit"
                    onChange={onChange}
                    disabled={data.type === 'D'}
                    value={commaFormatted(data.credit) || ''}
                    error={error[idx] ? error[idx].credit : ''}
                  />
                )}
              </td>
              <td>
                <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
              </td>
            </tr>
          ))
        }
        </tbody>
        <tfoot>
          <tr className="total">
            <td colSpan={5}>Total</td>
            <td>{this._getTotal('D')}</td>
            <td>{this._getTotal('K')}</td>
            <td />
          </tr>
          <tr className="manage-adjustment-journal__add-more">
            <td colSpan={8}>
              <div style={{ float: 'left', width: '50%', paddingRight: '5px' }}>
                <Button
                  icon="icon-plus"
                  title="Debit"
                  onClick={() => {
                    this._onAddList('D');
                  }}
                />
              </div>
              <div style={{ float: 'right', width: '50%', paddingLeft: '5px' }}>
                <Button
                  icon="icon-plus"
                  title="Kredit"
                  onClick={() => {
                    this._onAddList('K');
                  }}
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }
}
NonKasJournalForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  coa: PropTypes.object.isRequired,
  handleSearchUnit: PropTypes.object.isRequired,
  unitOptions: PropTypes.array.isRequired,
};
NonKasJournalForm.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
};
