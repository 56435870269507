import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { SearchSelect, Button, Select } from '../../../components/base';
import { commaFormatted } from '../../../utils/transformer.util';

const generateYear = () => {
  const year = new Date().getFullYear();
  const data = [];
  for (let i = 2020; i <= parseInt(year + 1, 10); i += 1) {
    const val = i.toString();
    const nextVal = i + 1;
    data.push({
      value: val,
      label: `${val} - ${nextVal}`,
    });
  }
  return data;
};

const getMonth = (m) => {
  const months = [
    'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus',
    'September', 'Oktober', 'November', 'Desember',
  ];

  const idx = parseInt(m, 10);
  if (isNaN(idx)) {
    return m;
  }

  return months[idx-1];
}

export default class SupervisionReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        year: '',
        coa_code: {},
      },
      report: {},
      summary: {},
      coa_options: [],
    };
    this._getReport = this._getReport.bind(this);
    this._getOptions = this._getOptions.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    this._getOptions();

  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (prevWorkingUnit !== workingUnit) {
      this._getReport();
    }
  }

  async _getReport() {
    const { handleGetSupervisionReport } = this.props;
    const { filters } = this.state;
    const { coa_code = {}, year = '' } = filters;
    const res = await handleGetSupervisionReport({
      coa: coa_code.value || '',
      year,
    });
    this.setState({ report: res.report, summary: res.summary, outstanding: res.outstanding });
  }

  async _getOptions(keyword = '') {
    const { handleGetSupervisionOptions } = this.props;
    const res = await handleGetSupervisionOptions({ value: keyword, type: 'coa' });
    this.setState({ coa_options: res });
  }

  _onChange(event) {
    const { value, name } = event.target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }));
  }

  render() {
    const {
      filters, report = {}, coa_options = [], summary = {}, outstanding = {},
    } = this.state;

    const { year = '', coa_code = {}} = filters;
    const { user } = this.props;
    const { school_unit } = user;

    return (
      <div className="balance-report">
        <div className="balance-report__form">
          <Select
            type="date"
            label="Tahun Akademis"
            name="year"
            data={generateYear()}
            value={year}
            placeholder="Pilih Periode"
            onChange={this._onChange}
          />
          <SearchSelect
            async={false}
            type="text"
            label="Supervisi"
            name="coa_code"
            value={coa_code}
            onSearch={this._getOptions}
            onChange={this._onChange}
            labelName="label"
            valueName="value"
            list={coa_options}
          />
          <Button
            title="Cari"
            onClick={this._getReport}
          />
        </div>
        <div>
          <div className="report__title">
            <h1>Laporan Supervisi</h1>
            <h3>{school_unit ? school_unit.name : ''}</h3>
            <h3>
              Supervisi { coa_code.label }
            </h3>
            <h3>
              Tahun Ajaran { year }
            </h3>
          </div>
          <div className="report__details">
            <table className="table">
              <thead>
                <tr>
                  <th rowSpan={3}>No.</th>
                  <th rowSpan={3} width="10%">Bulan</th>
                  <th colSpan={6}>PENCATATAN PENERIMAAN PEMBAYARAN</th>
                </tr>
                <tr>
                  <th rowSpan={2}>Tagihan</th>
                  <th colSpan={4}>Pembayaran</th>
                  <th rowSpan={2}>Tunggakan</th>
                </tr>
                <tr>
                  <th>Host To Host</th>
                  <th>Payment Gateway</th>
                  <th>Tunai</th>
                  <th>Jumlah</th>
                </tr>
              </thead>
              <tbody>
                {/* { Object.keys(outstanding).map((m, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td className="nominal">{m}</td>
                    <td className="nominal">{commaFormatted(outstanding[m].total)}</td>
                    <td className="nominal">{commaFormatted(outstanding[m].h2h)}</td>
                    <td className="nominal">{commaFormatted(outstanding[m].pg)}</td>
                    <td className="nominal">{commaFormatted(outstanding[m].offline)}</td>
                    <td className="nominal">{commaFormatted(outstanding[m].totalPayment)}</td>
                    <td className="nominal">{commaFormatted(outstanding[m].outstanding)}</td>
                  </tr>
                ))} */}
                { Object.keys(report).map((m, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td className="nominal">{getMonth(report[m].month)}</td>
                    <td className="nominal">{commaFormatted(report[m].total)}</td>
                    <td className="nominal">{commaFormatted(report[m].h2h)}</td>
                    <td className="nominal">{commaFormatted(report[m].pg)}</td>
                    <td className="nominal">{commaFormatted(report[m].offline)}</td>
                    <td className="nominal">{commaFormatted(report[m].totalPayment)}</td>
                    <td className="nominal">{commaFormatted(report[m].outstanding)}</td>
                  </tr>
                ))}
                <tr className="total">
                  <td colSpan="2">SALDO</td>
                  <td className="nominal">{commaFormatted(summary.total)}</td>
                  <td className="nominal">{commaFormatted(summary.h2h)}</td>
                  <td className="nominal">{commaFormatted(summary.pg)}</td>
                  <td className="nominal">{commaFormatted(summary.offline)}</td>
                  <td className="nominal">{commaFormatted(summary.totalPayment)}</td>
                  <td className="nominal">{commaFormatted(summary.outstanding)}</td>
                </tr>
              </tbody>
            </table>
            <div className="summary" style={{display: 'flex'}}>
              <div style={{flex: 1}}>
                <table style={{border: 'none', fontSize: 20, background: '#FFFFFF', width: '80%'}} width="80%" className="table no-index">
                  <thead>
                    <tr>
                      <th colSpan={2}><b><u>Hasil Supervisi</u></b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Uang yang harus diterima</td>
                      <td className="nominal">{commaFormatted(summary.total)}</td>
                    </tr>
                    <tr>
                      <td>Anak yang belum bayar</td>
                      <td className="nominal"><u>{commaFormatted(summary.outstanding)}</u></td>
                    </tr>
                    <tr>
                      <td>Uang yang masuk</td>
                      <td className="nominal">{commaFormatted(summary.totalPayment)}</td>
                    </tr>
                    <tr>
                      <td>Jumlah Pembayaran</td>
                      <td className="nominal"><u>{commaFormatted(summary.totalPayment)}</u></td>
                    </tr>
                    <tr>
                      <td>Saldo</td>
                      <td className="nominal"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{flex: 1}}>
                <table className="table no-index">
                  <thead>
                    <tr><th width="70%">BELUM BAYAR</th><th>NOMINAL</th></tr>
                  </thead>
                  <tbody>
                    { Object.keys(outstanding).map((m, index) => (
                      <tr>
                        <td>{m}</td>
                        <td className="nominal">{commaFormatted(outstanding[m].outstanding)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="total">
                      <td>SALDO TUNGGAKAN</td>
                      <td className="nominal">{commaFormatted(summary.outstanding)}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
SupervisionReport.propTypes = {
  user: PropTypes.object,
  handleGetSupervisionReport: PropTypes.func,
  handleGetSupervisionOptions: PropTypes.func,
};
SupervisionReport.defaultProps = {
  user: {},
  handleGetSupervisionReport: noop,
  handleGetSupervisionOptions: noop,
};
