import { connect } from 'react-redux';
import ManageNonKasJournal from '../../../../views/Finance/Bookkeeping/NonKasJournal/ManageNonKasJournal.bookkeeping.view';
import { manageNonKasJournal } from '../../../../states/thunks/finance.thunk';
import { get, create, update } from '../../../../states/thunks/nonCashJournal.thunk';
import { getCoaOptions, getUnitCodeOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getCoaOptions(payload)),
    handleGetNonKasJournal: payload => dispatch(get(payload)),
    handleCreateNonKasJournal: payload => dispatch(create(payload)),
    handleUpdateNonKasJournal: payload => dispatch(update(payload)),
    handleManageNonKasJournal: (payload, goBack) => dispatch(manageNonKasJournal(payload, goBack)),
    handleGetUnit: payload => dispatch(getUnitCodeOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageNonKasJournal);
