import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {isEmpty, noop,map, findIndex, isEqual,} from 'lodash';
// import {Pagination,Table,Loading,} from '../base';
import language from '../../languages';
import {Pagination,Loading,Button} from '../base';
import { formatData } from '../../../src/utils/transformer.util';

const initialContent = {
    loading: true,
    list: [],
    currentPage: 1,
    total: 1,
    totalPage: 1,
    error: false,
};

export default class 
ContentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
          content: initialContent,
          listAmount: 5,
        };
        this.isMount = false;
        this.onSearchContent = this.onSearchContent.bind(this);
        this.onChangeListAmount = this.onChangeListAmount.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.onClickView = this.onClickView.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onClickUnposting = this.onClickUnposting.bind(this);
    }

    componentDidMount() {
        this.isMount = true;
        const { content } = this.state;
        const { initialParams } = this.props;
        //console.log(this.props)
        if (isEmpty(content.list)) {
            this.onSearchContent({ page: 1, ...initialParams });
        }
    }

    componentDidUpdate(prevProps) {
        const { user, initialParams } = this.props;
        const { workingUnit } = user;
        const { user: prevUser } = prevProps;
        const { workingUnit: prevWorkingUnit } = prevUser;
    
        if (!isEqual(workingUnit, prevWorkingUnit)) {
            this.onSearchContent({ page: 1, ...initialParams });
        }
      }

    componentWillUnmount() {
        this.isMount = false;
    }

    onSearchContent(params) {
        //console.log(params)
        this.setState({
            content: initialContent,
        }, async () => {
            try {
            const { onSearchContent, user } = this.props;
            const { prm_school_units_id, workingUnit } = user;

            let unit_id = prm_school_units_id;

            if (unit_id === null && workingUnit && workingUnit.id) {
                unit_id = workingUnit.id;
            }

            const {
                result = [], currentPage = 1, total = 0, totalPage = 1,
            } = await onSearchContent(params, unit_id);
            if (this.isMount) {
                this.setState({
                content: {
                    loading: false,
                    list: result,
                    currentPage,
                    total,
                    totalPage,
                },
                });
            }
            } catch (err) {
            if (this.isMount) {
                this.setState({
                content: {
                    loading: false,
                    list: [],
                    currentPage: 1,
                    total: 1,
                    totalPage: 1,
                    error: true,
                },
                });
            }
            }
        });
    }
    
    onChangeListAmount(event) {
        const { keywords } = this.state;
        this.setState({
            listAmount: Number(event.target.value),
        });
        this.onSearchContent({ limit: event.target.value, keywords, page: 1 });
    }
    
    onChangePage(page) {
        const { listAmount, keywords } = this.state;
        const offset = listAmount * (page - 1);
        this.onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
        });
    }

    async getData(){
        const {handleGetDataPegawai, history, location} =this.props;
        const { data } = location.state;
        const datapegawai = await handleGetDataPegawai({nip:data.nip});
        this.setState({datapegawai: datapegawai.result, kota : datapegawai.result.unit_kerja.kota, 
            unit_kerja : datapegawai.result.unit_kerja.id_unit_kerja})
      }

    async onClickView(id){
        const { onClickView } = this.props;
        onClickView(id);
    }

    async onClickEdit(id){
        const { onClickEdit } = this.props;
        onClickEdit(id);
    }

    async onClickUnposting(id){
        const { onClickUnposting } = this.props;
        onClickUnposting(id);
    }

    render() {
        const {listAmount,content} = this.state;
        const{tableFields}  = this.props;
        const { user } = this.props;
        const { user_group } = user;
        const { permissions = [] } = user_group;
        const loadingProps = { show: content.loading };
        return(
            <div className="content-table">
                {
                    content.loading
                    ? <Loading loading={loadingProps} />
                    : (
                        <table className="table">
                            <thead>
                                <tr>
                                    {
                                        map(tableFields.label, (field, idx) => (
                                            <th key={`table_th_${idx}`}>{field}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    map(content.list, (list, idx) => (
                                        <tr key={`user_management_tr_${idx}`}>
                                          {
                                            map(tableFields.value, (field, fieldIdx) => {
                                              if (fieldIdx === 0) {
                                                return (
                                                   <td key={`table_${fieldIdx}_${idx}`}>{(idx + 1) + 
                                                    ((content.currentPage - 1) * listAmount)}</td>
                                                );
                                              }
                                              return (
                                                <td key={`table_${fieldIdx}_${idx}`}>{formatData(list, field)}</td>
                                              );
                                            })
                                          }

                                          <td>
                                            <div className="table__actions">
                                              {
                                                <Button
                                                    onClick= {()=>this.onClickView(list.id)}    
                                                    title="Preview"
                                                />
                                              }
                                              {(permissions.includes('unpost_sk') && list.is_posting)? (
                                                <Button
                                                    onClick= {()=>this.onClickUnposting(list.id)}    
                                                    title="Unposting"
                                                />
                                                
                                              ):null}
                                              {/* {
                                                <Button
                                                    onClick= {()=>this.onClickUnposting(list.id)}    
                                                    title="Unposting"
                                                />
                                              } */}
                                              {/* {
                                                <Button
                                                    onClick= {()=>this.onClickEdit(list.id)}    
                                                    title="Edit"
                                                />
                                              } */}
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                }
                            </tbody>
                        </table>
                    )
                }
                <div className="user-management__table-footer">
                    <p className="user-management__page-info">
                        {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                    </p>
                    <Pagination
                        totalPage={content.totalPage}
                        currentPage={content.currentPage}
                        onClick={this.onChangePage}
                    />
                </div>
            </div>
        );
    }

}
ContentTable.propTypes = {
    customThead: PropTypes.node,
    customAction: PropTypes.array,
    onSearchContent: PropTypes.func,
    onClickView:PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    onClickUnposting: PropTypes.func,
    tableFields: PropTypes.object.isRequired,
    initialParams: PropTypes.object,
    handleGetDataPegawai : PropTypes.func,
    user: PropTypes.object,
};
ContentTable.defaultProps = {
    customThead: null,
    customAction: [],
    onSearchContent: noop,
    onClickView:noop,
    onClickEdit: noop,
    onClickDelete: noop,
    onClickUnposting: noop,
    handleGetDataPegawai: noop,
    initialParams: {},
    user: {},
};