import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Pagination,
  Loading,
  Button,
} from '../../../../components/base';
import language from '../../../../languages';
import { formatData } from '../../../../utils/transformer.util';
import { FINANCE_NON_KAS_JOURNAL_TABLE_FIELDS } from '../../../../constants/finance/bookeeping.constant';

const initialContent = {
  loading: true,
  data: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class NonKasJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: initialContent,
      listAmount: 20,
    };
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickActionButton = this._onClickActionButton.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
  }

  componentDidMount() {
    this._onSearchContent({ page: 1 });
  }

  async _onSearchContent(params) {
    try {
      const { handleGetNonKasJournal } = this.props;
      const {
        data = [], current_page = 1, total = 0, last_page = 1,
      } = await handleGetNonKasJournal({ page: params });
      this.setState({
        content: {
          loading: false,
          list: data,
          currentPage: current_page,
          total,
          totalPage: last_page,
        },
      });
    } catch (err) {
      this.setState({
        content: {
          loading: false,
          list: [],
          currentPage: 1,
          total: 1,
          totalPage: 1,
          error: true,
        },
      });
    }
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-non-kas', { isEdit: false });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-non-kas', { data: { journal_number: val.journal_number }, isEdit: true });
  }

  async _onClickPostingButton(val) {
    const { handlePostingNonKasJournal } = this.props;
    await handlePostingNonKasJournal({ journal_number: val });
    this._onSearchContent({ page: 1 });    
  }

  _onClickActionButton(val) {
    const { history } = this.props;
    history.push(`/dashboard/keuangan/pembukuan/jurnal-non-kas/${val.journal_number}`);
  }

  _renderButtons(data) {
    if (!data) {
      return false;
    }

    if (data.is_posted === 0) {
      return ([
        <Button
          key="button_edit"
          onClick={() => this._onClickEdit(data)}
          title="Edit"
        />,
        <Button
          key="button_post"
          onClick={() => this._onClickPostingButton(data)}
          title="Post"
        />,
      ]);
    }
    return ([
      <Button
        key="button_preview"
        onClick={() => this._onClickActionButton(data)}
        title="Preview"
      />,
    ]);
  }

  render() {
    const {
      listAmount,
      content,
    } = this.state;
    const loadingProps = { show: content.loading };
    return (
      <div className="cash-journal">
        <Button
          title="Tambah Jurnal Kas"
          onClick={this._onClickAdd}
        />
        <div className="cash-journal__content">
          <div className="content-table">
            {
              content.loading
                ? <Loading loading={loadingProps} />
                : (
                  <table className="table">
                    <thead>
                      <tr>
                        {
                          FINANCE_NON_KAS_JOURNAL_TABLE_FIELDS.label.map((field, idx) => (
                            <th key={`table_th_${idx}`}>{field}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                      content.list.map((list, idx) => (
                        <tr key={`non_cash_journal_tr_${idx}`}>
                          {
                            FINANCE_NON_KAS_JOURNAL_TABLE_FIELDS.value.map((field, fieldIdx) => {
                              if (fieldIdx === 0) {
                                return (
                                  <td key={`table_${fieldIdx}_${idx}`}>{(idx + 1) + ((content.currentPage - 1) * listAmount)}</td>
                                );
                              }
                              return (
                                <td
                                  className={(field.type === 'number') ? 'nominal' : ''}
                                  key={`table_${fieldIdx}_${idx}`}
                                >{formatData(list, field)}
                                </td>
                              );
                            })
                          }

                          <td>
                            <div className="table__actions">
                              {this._renderButtons(list)}
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                )
            }
            <div className="non_cash_journal__table-footer">
              <p className="non_cash_journal__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this._onSearchContent}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
NonKasJournal.propTypes = {
  handleGetNonKasJournal: PropTypes.func.isRequired,
  handlePostingNonKasJournal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
